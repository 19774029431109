// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
// import { NavLink, useNavigate } from "react-router-dom";
// import Header from "../../../Layout/Header";
// import Footer from "../../../Layout/Footer";
// import axios from 'axios';
// import { SERVER_URL } from '../../../../config';
// import toast, { Toaster } from "react-hot-toast";

// const TeamMembers = () => {
//     const navigate = useNavigate();
//     const [errors, setErrors] = useState({});
//     const [teamList, setTeamList] = useState([]);


//     const email = localStorage.getItem("edu_email");


//     useEffect(() => {
//         const fetch_teamMembersList = async () => {

//             try {
//                 const response = await axios.get(`${SERVER_URL}/api/fetch_teamMembersList`, {
//                     params: { email },
//                 });

//                 console.log("response",response)

//                 if (response.status === 200) {
//                     console.log('team members list:', response.data.data);
//                     setTeamList(response.data.data)
//                 } else {
//                     console.error('Unexpected response:', response);
//                 }
//             } catch (error) {
//                 console.error('Error fetching user step data:', error.response?.data || error.message);
//             }
//         };

//         fetch_teamMembersList();
//     }, []);






//     return (
//         <div className="w-full bg-Fabcbg h-screen pb-10 overflow-hidden overflow-y-auto bg-black bg-cover font-poppins text-white">
//             <Header />
//             <Toaster />
//             <div className="fade-in">
//                 <NavLink to='/jordan-event' > <button className='mx-[5%] text-white flex items-center'>
//                     <FontAwesomeIcon icon={faChevronLeft} className='text-sm mr-2' />
//                     Back
//                 </button>
//                 </NavLink>
//                 <div className="text-center">
//                     <h1
//                         style={{
//                             backgroundClip: "text",
//                             WebkitBackgroundClip: "text",
//                             color: "transparent",
//                             backgroundImage: "linear-gradient(to right, #31989B, #4A5CA4)",
//                         }}
//                         className=" text-2xl   font-semibold"
//                     >
//                         Events
//                     </h1>
//                     <p className="text-xl mt-3 font-semibold">Jordon Skill Summit</p>
//                     <p className=" text-xs italic mt-2">Dec 24 - Feb 25</p>
//                 </div>

//                 <div className="w-[90%] mx-auto mt-10 flex md:flex-row flex-col justify-between gap-10 md:gap-0">
//                     <div className="w-full md:w-[45%] ">
//                         <div className=" bg-[#D9D9D91C] rounded-2xl p-5 h-full">
//                             <p className="font-bold text-center">Team Name - {teamList.teamLeader?.teamName}</p>
//                             <div className=" flex flex-col justify-between h-full pb-5">
//                                 <ol className="mt-5 pl-5">
//                                     {teamList && teamList.teamLeader && (
//                                         <div className="mt-4">
//                                             <h3 className="font-semibold">Team Leader:</h3>
//                                             <div className="flex items-center gap-3">
//                                                 <p className="text-sm">
//                                                     {teamList.teamLeader.teamLeaderName} ({teamList.teamLeader.email})
//                                                 </p>
//                                                 <div className="w-2 h-2 rounded-full bg-green-600"></div>
//                                             </div>
//                                         </div>
//                                     )}

//                                     {teamList && teamList.teamMembers && teamList.teamMembers.length > 0 ? (
//                                         <ul>
//                                             <h3 className="font-semibold">Team Members:</h3>
//                                             {teamList.teamMembers.map((member, index) => (
//                                                 <li
//                                                     key={index}
//                                                     className="flex justify-between items-center"
//                                                 >
//                                                     <div className="flex items-center gap-3">
//                                                         <p className="text-sm">
//                                                             {member.teamMemberName} ({member.email})
//                                                         </p>
//                                                         <div className="w-2 h-2 rounded-full bg-green-600"></div>
//                                                     </div>
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     ) : (
//                                         <p>No team members found.</p>
//                                     )}
//                                 </ol>
//                                 <div
//                                     className={` flex  ${teamList.length > 0
//                                             ? " flex-row justify-between items-end"
//                                             : "flex-col-reverse justify-center items-center "
//                                         } `}
//                                 >
//                                     <div>
//                                         <div className="flex items-center gap-1 ">
//                                             <div className="w-2 h-2 rounded-full bg-green-600"></div>
//                                             <p className="text-xs italic">
//                                                 Pre-skill assessment completed
//                                             </p>
//                                         </div>
//                                         <div className="flex items-center gap-1 ">
//                                             <div className="w-2 h-2 rounded-full bg-yellow-600"></div>
//                                             <p className="text-xs italic">
//                                                 Yet to take Pre-skill assessment{" "}
//                                             </p>
//                                         </div>
//                                     </div>
//                                     <img src="/assets/Mascots/avatar.svg" className="w-5/12" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     );
// };

// export default TeamMembers;








import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Header from "../../Seeker/LoginHeader";
import Footer from "../../Footer";
import axios from 'axios';
import { SERVER_URL } from '../../Config';
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";

const TeamMembers = ({onClose}) => {
    const [teamList, setTeamList] = useState({});
    const [loading, setLoading] = useState(true);
    const email = useSelector((state) => state.seeker_email);

    useEffect(() => {
        const fetch_teamMembersList = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${SERVER_URL}/api/fetch_teamMembersList`, {
                    params: { email },
                });

                if (response.status === 200) {
                    setTeamList(response.data.data);
                } else {
                    console.error('Unexpected response:', response);
                }
            } catch (error) {
                console.error('Error fetching user step data:', error.response?.data || error.message);
            }
            finally {
                setLoading(false); 
            }
        };

        fetch_teamMembersList();
    }, []);

    const getStatusColor = (completed) => {
        return completed ? "bg-green-600" : "bg-orange-600";
    };

    return (
        <div className="w-[70%] mx-auto py-10 overflow-hidden overflow-y-auto 0 bg-cover font-poppins text-white">
            <Toaster />
            <div className="fade-in ">
                {/* <NavLink to='/jordan-event'> */}
                
                {/* </NavLink> */}
                {/* <div className="text-center">
                    <h1
                        style={{
                            backgroundClip: "text",
                            WebkitBackgroundClip: "text",
                            color: "transparent",
                            backgroundImage: "linear-gradient(to right, #31989B, #4A5CA4)",
                        }}
                        className=" text-2xl font-semibold"
                    >
                        Events
                    </h1>
                    <p className="text-xl mt-3 font-semibold text-[#F29D38]">Jordon Skill Summit</p>
                     <p className=" text-xs italic mt-2">Dec 24 - Feb 25</p> 
                </div> */}

                <div className="w-full md:w-[60%] mx-auto">
                <div className="w-full flex justify-end " >
                    <button onClick={() => onClose()} className=' text-white flex items-center'>
                        <FontAwesomeIcon icon={faXmark} className='' />
                    </button>
                    </div>
                    {loading ? (
                       
                        <div className="flex justify-center items-center h-48">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-orange-600"></div>
                        </div>
                    ) : (

                    <div className="w-full md:w-[95%] mx-auto mt-5">
                        <div className=" bg-[#D9D9D91C] rounded-2xl p-5 h-full">
                       
                            <p className="font-bold text-center">Team Name - {teamList.teamLeader?.teamName}</p>
                            <div className=" flex flex-col justify-between h-full pb-5">
                                <ol className="mt-5 pl-5">
                                    {teamList.teamLeader && (
                                        <div className="mt-4">
                                            <h3 className="font-semibold text-[#F29D38] font-space">Team Leader:</h3>
                                            <div className="flex items-center gap-3">
                                                <p className="text-sm">
                                                    {teamList.teamLeader.teamLeaderName} ({teamList.teamLeader.email})
                                                </p>
                                                <div className={`w-2 h-2 rounded-full ${getStatusColor(teamList.teamLeader.completedAssessment)}`}></div>
                                            </div>
                                        </div>
                                    )}

                                    {teamList.teamMembers && teamList.teamMembers.length > 0 ? (
                                        <ul className="mt-3" >
                                            <h3 className="font-semibold text-[#F29D38] font-space ">Team Members:</h3>
                                            {teamList.teamMembers.map((member, index) => (
                                                <li
                                                    key={index}
                                                    className="flex justify-between items-center mt-1"
                                                >
                                                    <div className="flex items-center gap-3">
                                                        <p className="text-sm">
                                                            {member.teamMemberName} ({member.email})
                                                        </p>
                                                        <div className={`w-2 h-2 rounded-full ${getStatusColor(member.completedAssessment)}`}></div>
                                                    </div>
                                                </li>
                                            ))}
                                            


                                        </ul>
                                    ) : (
                                        <p>No team members found.</p>
                                    )}
                                </ol>
                                <div
                                    className={`flex
                                        flex-row justify-between items-end`}
                                >
                                    <div>
                                        <div className="flex items-center gap-1">
                                            <div className="w-2 h-2 rounded-full bg-green-600"></div>
                                            <p className="text-xs italic">
                                                Pre-skill assessment completed
                                            </p>
                                        </div>
                                        <div className="flex items-center gap-1">
                                            <div className="w-2 h-2 rounded-full bg-orange-600"></div>
                                            <p className="text-xs italic">
                                                Yet to take Pre-skill assessment{" "}
                                            </p>
                                        </div>
                                    </div>
                                    <img src="/assets/Mascots/avatar.svg" className="w-5/12" />
                                </div>
                            </div>
                        </div>
                    </div>
                  )}
                </div>
            </div>
        </div>
    );
};

export default TeamMembers;
