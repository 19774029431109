import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setRecruiterEmail } from '../store';

export default function LoginHeader() {

  const [isDropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const email = useSelector((state) => state.recruiter_email);
  console.log("Email Logged In", email)

  const dispatch = useDispatch();

  const Logout = () => {
    dispatch(setRecruiterEmail(""));
    window.location = '/';
  };

  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };

  const handleClickOutside = (event) => {
    if (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdown]);


  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };



  const [ExpandSkill, setExpandSkill] = useState(false)
  const [ExpandCourse, setExpandCourse] = useState(false)

  const handleExpandSkill = () => {
    setExpandSkill(!ExpandSkill);
  };

  const handleExpandCourse = () => {
    setExpandCourse(!ExpandCourse);
  };

  // Only open the hovered button's dropdown
  const handleMouseEnterCourse = () => {
    setExpandCourse(true);
  };

  const handleMouseLeaveCourse = () => {
    setExpandCourse(false);
  };

  const handleMouseEnterSkill = () => {
    setExpandSkill(true);
  };

  const handleMouseLeaveSkill = () => {
    setExpandSkill(false);
  };

  return (
    <div>
      <nav className='flex p-7 lg:px-10 font-poppins'>
        <div className='w-[85%] lg:w-[15%]'>
          <div className='w-[80%]'>
            <a href='/RMS/Recruiter'>  <img src='/assets/d5art_white.png' className='h-10'></img> </a>
          </div>
        </div>
        <div className='w-[15%] lg:w-[85%] hidden md:table'>
          <div className='flex'>
            <div className='w-[80%] relative text-gray-300 font-bold flex justify-center gap-16 mt-3  '>
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='/RMS/Recruiter'>Home</a>
              <div>
                <div className='relative' onMouseLeave={() => { handleMouseLeaveCourse(); }}>
                  <a href='https://edu.d5art.com'>
                    <button
                      className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
                      onClick={handleExpandCourse}
                      onMouseEnter={handleMouseEnterCourse}
                    >
                      Courses
                      <span className='ml-2'>&#9660;</span> {/* Unicode down arrow */}
                    </button>
                  </a>
                  <div
                    className={`absolute z-20 -left-[75%] mt-2 rounded-md bg-black/75 backdrop-blur-sm w-[10rem] transition-all duration-700 overflow-y-auto 
          ${ExpandCourse ? 'max-h-[300px] border border-white/30' : 'max-h-0'}`}
                  >
                    <ul className="p-5 text-sm text-center flex flex-col gap-2 divide-transparent">
                      <a href='https://edu.d5art.com/Courses' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Explore Courses</a>
                      <a href='https://edu.d5art.com/dashboard' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Learning Dashboard</a>
                      <a href='https://edu.d5art.com/dashboard?tab=certificate' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Certificate</a>
                      <a href='https://edu.d5art.com/dashboard?tab=wallet' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Wallet</a>
                      <a href='https://edu.d5art.com/dashboard?tab=result' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Assessment</a>

                    </ul>
                  </div>
                </div>
              </div>

              <div className='relative' onMouseLeave={() => { handleMouseLeaveSkill(); }}>
                <a href='/RMS/Recruiter/skillarenalist'>
                  <button
                    className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
                    onClick={handleExpandSkill}
                    onMouseEnter={handleMouseEnterSkill}
                  >
                    Skill Exchange
                    <span className='ml-2'>&#9660;</span> {/* Unicode down arrow */}
                  </button>
                </a>
                <div
                  className={`absolute z-20 -left-6 mt-2 rounded-md bg-black/75 backdrop-blur-sm w-[10rem] transition-all duration-700 overflow-y-auto 
                    ${ExpandSkill ? 'max-h-[300px] border border-white/30' : 'max-h-0'}`}
                >
                  <ul className="p-5 text-sm text-center flex flex-col gap-2 divide-transparent">

                    <a href='/RMS/Recruiter/RecruiterDashboard?tab=nft' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>My Profile</a>
                    <a href='/RMS/Recruiter/RecruiterDashboard?tab=offers' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>My Offers</a>
                    <a href='/RMS/Recruiter/RecruiterDashboard?tab=chat' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Chat Window</a>
                    <a href='/RMS/Recruiter/RecruiterDashboard?tab=wallet' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Wallet</a>
                  </ul>
                </div>
              </div>
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='https://edu.d5art.com/Events' >Events</a>
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='/ex/recruiter/faq'>FAQ</a>

            </div>


            <div className='w-[20%] '>
              <div className="relative">
                <button className="flex px-5 py-2  text-white" onClick={handleDropdownToggle}>
                  <button className="p-3 rounded-full border border-sky-500 mr-3"></button>
                  <div className='absolute -translate-x-2 -translate-y-2'><img className='h-[3rem]' src='/assets/image 90.png'></img></div>{email.substr(0, 10) + "...."}
                  <i className="fa-solid fa-chevron-down pl-3 mt-1"></i>
                </button>

                {dropdownVisible && (
                  <div className="absolute top-full z-20 left-0 mt-2 bg-white border border-gray-300 rounded-md shadow-md animate__animated animate__fadeInUp ">
                    <div className='py-2 px-9 rounded-md bg-black text-white'>
                      <a href='/RMS/Recruiter/RecruiterDashboard' className='text-center'>
                        <i className="fa-solid fa-user pr-3"></i> Dashboard</a>
                      <button onClick={Logout} className='text-center'>
                        <i className='fa-solid fa-door-open pr-3'></i>Log out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='w-[15%] table lg:hidden grid content-center'>
          <div className='flex justify-center '>
            <button onClick={handleDropdown} ref={buttonRef}>
              <button className="p-3 rounded-full border border-sky-500 mr-3"></button>
              <div className='absolute -translate-y-10 -translate-x-2'><img className='h-[3rem]' src='/assets/image 90.png'></img></div>
            </button>

            {isDropdown && (
              <div className='dropdown-content text-black z-20 w-[10rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp' ref={dropdownRef}>
                <div className='flex flex-col gap-3 bg-black text-white text-center rounded-lg p-5'>
                  <a href='/RMS/Recruiter'>Home</a>
                  <a href='https://edu.d5art.com/' className='hidden lg:inline'>Courses</a>
                  <a href='/RMS/Recruiter/SkillArena'>Skill Exchange</a>
                  <a href='https://edu.d5art.com/Events' className='hidden lg:inline'>Events</a>
                  <a href='/ex/recruiter/faq' className='hidden lg:inline'>FAQ</a>
                  <a href='/RMS/Recruiter/RecruiterDashboard' className='text-center'>
                    <i className="fa-solid fa-user pr-3"></i> Dashboard</a>
                  <button onClick={Logout} className='text-center'>
                    <i className='fa-solid fa-door-open pr-3'></i>Log out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
