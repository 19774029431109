import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ValidatorHeader from './ValidatorHeader';
import Footer from '../Footer';    
import { toast, Toaster } from 'react-hot-toast';                            

export default function ManagePage() {
    const [questionSets, setQuestionSets] = useState([]);
    const validatorEmail = useSelector((state) => state.validator_email);
    console.log(validatorEmail)


    useEffect(() => {
        const fetchQuestionSets = async () => {
            try {
                const response = await axios.get(`https://ex.d5art.com/api/getQA?email=${validatorEmail}`);
                const { data } = response;
                if (data.success) {
                    setQuestionSets(data.data);
                } else {
                    console.error('Error fetching question sets:', data.message);
                }
            } catch (error) {
                console.error('Error fetching question sets:', error);
            }
        };

        fetchQuestionSets();
    }, []);

    console.log(questionSets);

    return (
        <div className='font-poppins bg-[#191919]  h-screen overflow-hidden overflow-y-scroll text-white' style={{backgroundImage:'url("/assets/FABC.png")'}}>
        <ValidatorHeader />
        <Toaster />
            <div className='flex justify-center pb-20'>
                <div className='w-[90%] 2xl:w-[80%] flex flex-col gap-10 2xl:gap-20 pt-10'>
                {questionSets.length === 0 ? (
                        // Show image if there are no question sets
                        <div>
                            <img src="/assets/no_question_set_found.png" alt="no projects" className="mx-auto md:w-[30%]" />
                        </div>
                    ) : (
                    questionSets.map((questionSet, index) => (
                        <div key={index} className='border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg shadow-[#04F7FF80] rounded-3xl 2xl:text-xl'>
                            <div className='flex flex-col gap-2 md:flex-row md:gap-0 justify-around border-b border-[#04F7FF80] p-5'>
                                <div className='md:w-[30%]'>
                                    <p className='text-2xl 2xl:text-4xl'>{`Question Set - ${index + 1}: ${questionSet.setName}`}</p>
                                </div>
                                <div className='md:w-[35%]'>
                                    <p>{`Last modified: ${questionSet.created_at}`}</p>
                                </div>
                                <div className='md:w-[25%] text-start md:text-center'>
                                    <p>{`Created: ${questionSet.created_at}`}</p>
                                </div>
                            </div>
                            <div className='flex flex-col gap-3 md:gap-0 md:flex-row justify-around  p-5'>
                                <div className='md:w-[30%] flex flex-col gap-2 md:gap-3'>
                                    <div className='flex'>
                                        <div className='border-4 border-[#04F7FF80] rounded-xl w-2 h-2 p-1 mr-3 mt-1'></div>
                                        <p className='w-[50%] flex justify-between'>{`Questions  - ${questionSet.totalQuestions}`}</p>
                                    </div>
                                    <div className='flex'>
                                        <div className='border-4 border-[#04F7FF80] rounded-xl w-2 h-2 p-1 mr-3 mt-1'></div>
                                        <p className='w-[50%] flex justify-between'>{`MCQ's  - ${questionSet.totalMCQ}`}</p>
                                    </div>
                                    <div className='flex'>
                                        <div className='border-4 border-[#04F7FF80] rounded-xl w-2 h-2 p-1 mr-3 mt-1'></div>
                                        <p className='w-[50%] flex justify-between'>{`Objective  - ${questionSet.totalObjective}`}</p>
                                    </div>
                                    <div className='flex'>
                                        <div className='border-4 border-[#04F7FF80] rounded-xl w-2 h-2 p-1 mr-3 mt-1'></div>
                                        <p className='w-[50%] flex justify-between'>{`Coding  - ${questionSet.totalCoding}`}</p>
                                    </div>
                                    <div className='flex'>
                                        <div className='border-4 border-[#04F7FF80] rounded-xl w-2 h-2 p-1 mr-3 mt-1'></div>
                                        <p className='w-[50%] flex justify-between'>{`Score  - ${questionSet.totalScore}`}</p>
                                    </div>
                                </div>
                                <div className='md:w-[35%] grid content-center gap-3'>
                                    <div className='flex'>
                                        <div><img src='/assets/Checked Checkbox.png' className='w-8' alt='Checked Checkbox'></img></div>
                                        <p className='ml-2 mt-1'>{`Validators used : ${questionSet.attemptedSeekers}`}</p>
                                    </div>
                                    <div className='flex'>
                                        <div><img src='/assets/Logic Data Types.png' className='w-8' alt='Logic Data Types'></img></div>
                                        <p className='ml-2 mt-1'>{`Average score : ${questionSet.averageScore}`}</p>
                                    </div>
                                    <div><p>Qualification Percentage % : 50 </p></div>
                                    <div className='py-2'>
                                        <div className='w-[70%] h-4 bg-white/20 rounded-lg'>
                                            <div className='bg-[#04F7FF80] w-[80%] h-4 rounded-lg'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:w-[25%] text-center grid content-center gap-3'>
                                    <div><p>{`Pending Validations: ${questionSet.pendingValidations}`}</p></div>
                                    <div><p>{`Completed Validations: ${questionSet.completedValidations}`}</p></div>
                                    <div>
                                        <a href={`/RMS/Validator/ValidateQA?setName=${questionSet.setName}`}>
                                            <button className='px-5 py-2 rounded-full bg-gradient-to-r from-[#0087A5] to-[#0E2DA7]'>Validate Now</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
                </div>
            </div>
            <Footer />
        </div>
    );
}
