
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ValidatorHeader from './ValidatorHeader';
import { Navbar } from '@material-tailwind/react';
import { toast, Toaster } from 'react-hot-toast';
import Footer from '../Footer'; 


export default function ResultPage() {

    const validatorEmail = useSelector((state) => state.validator_email);
    const location = useLocation();

    const { setName } = queryString.parse(location.search);
    const { nft_id } = queryString.parse(location.search);
    const [questions, setQuestions] = useState([]);
    const [totalMarks, setTotalMarks] = useState(0);
    const [answeredQuestions, setAnsweredQuestions] = useState({});
    const [totalPoints, setTotalPoints] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [lastSelectedButtons, setLastSelectedButtons] = useState({});

    const [validatorWalletAddress, setValidatorWalletAddress] = useState('');
    const [SeekerEmail, setSeekerEmail] = useState('');

    const [projectMarks, setProjectMarks] = useState(0);
    const [finalPercentage, setFinalPercentage] = useState(0);


    // console.log("projectMarks", projectMarks)
    // console.log("finalPercentage", finalPercentage)

    useEffect(() => {
        const fetchAnswers = async () => {
            try {
                const response = await axios.get('https://ex.d5art.com/api/fetchingAnswers', {
                    params: {
                        nft_id: nft_id,
                        setName: setName,
                        validatorEmail: validatorEmail
                    }
                });
                if (response.data.success) {
                    const questionsData = response.data.data[0].answers;
                    setQuestions(questionsData);
                    console.log("questionsData", questionsData)
                    const sumPoints = questionsData.reduce((acc, question) => acc + question.points, 0);
                    setTotalPoints(sumPoints);
                    console.log("sumPoints", sumPoints)
                    const seekerEmail = response.data.data[0].seeker_email;
                    setSeekerEmail(seekerEmail);
                    console.log('Total Points:', seekerEmail);
                    console.log(response);
                }
            } catch (error) {
                console.error('Error fetching answers:', error);
            }
        };

        fetchAnswers();
    }, [setName, nft_id, validatorEmail]);

    useEffect(() => {
        if (totalPoints > 0) {
            const calculatedPercentage = (totalMarks / totalPoints) * 70;
            setPercentage(calculatedPercentage);
        }
    }, [totalMarks, totalPoints]);

    useEffect(() => {
        // const percentage = 70

        const final_project_marks = (projectMarks / 30) * 30;
        const combinedPercentage = percentage + final_project_marks;
        setFinalPercentage(combinedPercentage);
    }, [percentage, projectMarks]);
    console.log(percentage.toFixed(2))



    // get certifcate and project details 

    const [certificate_path, setcertificate_path] = useState();
    const [project_description, setproject_description] = useState();

    const fetchCertificates = async () => {
        try {
            const response = await axios.get('https://ex.d5art.com/api/fetchCertificates', {
                params: {
                    setName,
                    SeekerEmail
                }
            });

            console.log("response for cert", response.data.data[0].certificate_path)
            if (response.data.success) {
                const { certificate_path, project_description } = response.data.data[0];
                // setcertificate_path(certificate_path);
                setcertificate_path(`/Certificates/${certificate_path}`);
                setproject_description(project_description);
            } else {
                console.error('No records found');
            }
        } catch (error) {
            console.error('Error fetching certificates:', error);
        }
    };

    const fileExtension = certificate_path?.split('.').pop().toLowerCase();

    // Check if the file is a PDF or an image
    const isPdf = fileExtension === 'pdf';
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);


    const handleSubmit = async () => {
        try {

           // await pay_validated_amount();

            let skill_level = '';
            let result = '';

            if (finalPercentage > 91) {
                skill_level = "ACHIEVERS";
            } else if (finalPercentage > 81 && finalPercentage < 90) {
                skill_level = "INVENTORS";
            } else if (finalPercentage > 71 && finalPercentage < 80) {
                skill_level = "BUILDERS";
            } else if (finalPercentage > 61 && finalPercentage < 70) {
                skill_level = "EXPLORERS";
            } else {
                skill_level = "STARTERS";
            }

            if (finalPercentage > 50) {
                result = "PASS";
            } else {
                result = "FAIL";
            }

            const data = {
                marks_obtained: totalMarks,
                total_marks: totalPoints,
                percentage: finalPercentage.toFixed(2),
                skill_level: skill_level,
                result: result,
                nft_id: nft_id,
                validatorEmail: validatorEmail,
                Set_name: setName,
                seeker_email: SeekerEmail
            };

            const response = await axios.post('https://ex.d5art.com/api/answers_set', data);
            console.log(response);

            if (response.data.status === 'success') {
                console.log('Validation Completed Successfully');
                setTimeout(() => {
                    window.location.href = "/RMS/Validator/MangeQA"
                }, 1000)
            } else {
                console.log('Something went wrong. Please try again later.');
            }
        } catch (err) {
            console.error('Error:', err);
            console.log('Something went wrong. Please try again later.');
        }
    };

    const handleCorrectAnswer = (points, index) => {
        if (!answeredQuestions[index]) {
            setTotalMarks(prevTotalMarks => prevTotalMarks + points);
            setAnsweredQuestions(prevState => ({ ...prevState, [index]: true }));
            setLastSelectedButtons(prevState => ({ ...prevState, [index]: 'correct' }));
        }
    };

    const handleWrongAnswer = (points, index) => {
        if (answeredQuestions[index]) {
            setTotalMarks(prevTotalMarks => prevTotalMarks - points);
            setLastSelectedButtons(prevState => ({ ...prevState, [index]: 'wrong' }));
        }
        setAnsweredQuestions(prevState => ({ ...prevState, [index]: false }));
    };


    console.log(totalMarks)




    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/fetch_walletAddress_from_validator?validatorEmail=${validatorEmail}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log('5555', data.data[0].walletAddress);
                console.log('5555', data);


                if (response.ok) {

                    setValidatorWalletAddress(data.data[0].walletAddress);


                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (validatorEmail) {
            fetchData();
        }
    }, [validatorEmail]);


    // admin pay amount to validator
    const [isLoading, setIsLoading] = useState(false);

    const pay_validated_amount = async (e) => {

        // const validatorAddress = '0x797fFC09444EAe34C1700948E41ae185577aFd83';

        const validatorAddress = validatorWalletAddress;
        const amount = 10;

        setIsLoading(true);

        try {
            const response = await fetch('https://ex.d5art.com/api/transfer_amount_from_admin_to_validator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ validatorAddress, amount, validatorEmail })
            });

            if (response.ok) {
                console.log('Payment submitted successfully');

                // window.location.href = "/RMS/Validator/MangeQA";

            } else {
                console.error('Error submitting payment');
            }
        } catch (error) {
            console.error('Error submitting payment:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const [isVerify, setVerify] = useState(false);
    const handleVerify = () => {
        setVerify(!isVerify)
    }

    // const handleProjectMarksChange = (e) => {
    //     setProjectMarks(parseFloat(e.target.value) || 0);
    // };

    const [isProjectMarksValid, setIsProjectMarksValid] = useState(true);
    
    const handleProjectMarksChange = (e) => {
        const value = parseFloat(e.target.value);
        if (value < 0 || value > 30) {
            setIsProjectMarksValid(false);
            toast.error('Certificate and Project marks must be between 0 to 30.');
        } else {
            setProjectMarks(value);
            setIsProjectMarksValid(true);
        }
    };


    return (

        <div className='font-poppins pb-16 md:pb-10 bg-[#191919]' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
            <ValidatorHeader />
            <Toaster />
            <div className='flex justify-center pb-20'>
                <div className='flex flex-col w-[90%] 2xl:w-[85%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl'>
                    <div className=' flex justify-center text-white border-b-[2px] border-[#04F7FF80]'>
                        <div className='w-[90%] 2xl:w-[85%] flex flex-col md:flex-row gap-5 md:gap-10 py-14'>
                            <div className='md:w-[70%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl p-5 flex justify-center 2xl:text-xl'>
                                <div className='grid content-center gap-3 2xl:gap-5 w-[100%] md:w-[80%] md:px-0 overflow-hidden overflow-x-auto'>
                                    <p className='md:flex '><p className='text-xl 2xl:text-[26px] font-bold w-[30%]'>NFT ID </p><p className='flex '><span>:</span><p className='ml-3 text-[#04F7FF80] text'>{nft_id.slice(0, 20)}</p></p></p>
                                    <p className='md:flex '><p className='text-xl 2xl:text-[26px] font-bold w-[30%]'>Validate On</p><p className='flex'><span>:</span><p className='ml-3 text-[#04F7FF80]'>{new Date().toLocaleString()}</p></p></p>
                                    <p className='md:flex '><p className='text-xl 2xl:text-[26px] font-bold w-[30%]'>Domain</p><p className='flex'><span>:</span><p className='ml-3 text-[#04F7FF80]'>{setName}</p></p></p>
                                </div>
                            </div>
                            <div className='md:w-[30%] text-center border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl p-5 grid content-center  2xl:text-xl'>
                                <p>Check the certificates</p>
                                <div className='flex justify-center'><button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg mt-3'><button className='bg-black px-4 text-sm py-1 rounded-lg' onClick={() => { handleVerify(); fetchCertificates(); }}>Verify</button></button>
                                </div>
                            </div>
                            {/*
                                <div className='md:w-[30%] flex justify-center'>
                                <img src='/assets/image 93.png' className='w-[12.5rem] 2xl:w-[15rem]'></img>
                            </div>
                                */}
                        </div>
                    </div>

                    {isVerify && (
                        <div className="z-10 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                            <div className='w-[95%] md:w-[60%] text-white bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 animate__animated animate__fadeIn'>
                                <div className='flex justify-end'>
                                    <i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={handleVerify}></i>
                                </div>
                                <div className='p-7 pt-0 flex flex-col md:flex-row gap-5'>
                                    <div className='md:w-[50%] flex items-center'>
                                        {/* Displaying the certificate image */}
                                        {/* <img src="/Certificates/certificate-1721745201164-20269059.pdf" alt="Certificate" className="w-full h-auto" />
                                         */}
                                        {certificate_path ? (
                                            isPdf ? (
                                                <embed
                                                    src={certificate_path}
                                                    type="application/pdf"
                                                    width="100%"
                                                    height="400px"
                                                    alt="Certificate"
                                                />
                                            ) : isImage ? (
                                                <img
                                                    src={certificate_path}
                                                    alt="Certificate"
                                                    className="w-full h-auto"
                                                />
                                            ) : (
                                                <p>Unsupported file type</p>
                                            )
                                        ) : (
                                            <p>Loading certificate...</p>
                                        )}
                                    </div>
                                    <div className='md:w-[50%] grid content-between'>
                                        <div>
                                            <p className='font-bold'>Description :</p>
                                            <p className='text-white/75 text-sm mt-3 h-[120px] overflow-hidden overflow-y-auto'>
                                                {project_description}
                                            </p>
                                        </div>
                                        <div>
                                            <p className='mt-3 font-bold'>Update mark :</p>
                                            <p className='text-white/75 text-sm mt-3 '>Certificate and Project marks must be between 0 to 30</p>
                                            <div className='flex gap-3'>
                                                <input
                                                    className='bg-transparent outline-none w-[100%] mt-3 rounded-lg py-1.5 px-5 border border-sky-700'
                                                    type="number"
                                                    min={0}
                                                    max={30}
                                                    onChange={handleProjectMarksChange}
                                                />
                                                <div className='flex justify-center mt-3'>
                                                    <button
                                                        className='font-play text-sm px-5 py-1.5 border border-sky-700 shadow-sm shadow-sky-700 rounded-lg text-white'
                                                        onClick={handleVerify}
                                                        disabled={!isProjectMarksValid}
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}



                    {isLoading && (
                        // Show loading spinner while isLoading is true
                        <div className="flex justify-center items-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
                        <div className="flex flex-col items-center">
                            <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-blue-800 mb-4"></div>
                            <p className="text-auto font-bricolagegrotesque text-white/100">Transferring token rewards, please wait...</p>
                        </div>
                    </div>
                    )}


                    {questions.map((question, index) => (
                        <div key={index} className='py-12 flex justify-center text-white'>
                            <div className='w-[90%] 2xl:w-[85%] flex flex-col gap-5'>
                                <div className='flex justify-between w-full'>
                                    <div><p className='text-2xl font-bold'>{`Question - ${index + 1} :`}</p></div>
                                    <div><button className='border border-[#04F7FF80] shadow-md shadow-[#04F7FF80] px-5 py-2 text-sm rounded-xl'>{question.type === 'type1' ? 'MCQ' : question.type === 'type2' ? 'Descriptive' : 'Coding'} Points: {question.points}</button>
                                    </div>
                                </div>
                                <div className='px-10 py-5 text-lg font-bold bg-white/15 rounded-2xl border-[2px] border-[#04F7FF80]'>
                                    <p>{question.question}</p>

                                </div>
                                {question.type === 'type1' && (
                                    <div className='px-10 py-5'>
                                        {question.options.map((option, idx) => {

                                            return (
                                                <p key={idx} className={`border border-[#04F7FF80] shadow-md shadow-[#04F7FF80] px-5 py-2 text-sm rounded-xl text-[#1EEF32]' rounded-3xl h-12 outline-none p-7' ${option.toString() === question.answer.toString() ? 'bg-blue-400' : ''}`}>{option}</p>
                                            );
                                        })}
                                    </div>
                                )}



                                {question.type === 'type2' ? (
                                    <div>
                                        <p className='w-full bg-transparent border border-[#04F7FF80] shadow-md shadow-[#04F7FF80] rounded-3xl h-44 outline-none p-7'>{question.answer}</p>
                                    </div>
                                ) : question.type === 'type3' ? (
                                    <div>
                                        <p className='w-full bg-transparent border border-[#04F7FF80] shadow-md shadow-[#04F7FF80] rounded-3xl h-44 outline-none p-7'>{question.answer}</p>
                                    </div>
                                ) : null}
                                <div className='flex justify-end gap-7'>
                                    <div>
                                        <button
                                            onClick={() => handleCorrectAnswer(question.points, index)}
                                            className={`border border-[#04F7FF80] shadow-md shadow-[#04F7FF80] px-5 py-2 text-sm rounded-xl text-[#1EEF32] ${lastSelectedButtons[index] === 'correct' ? 'bg-green-100' : ''}`}
                                        >
                                            <i className="fa-solid fa-check mr-3"></i>Correct
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => handleWrongAnswer(question.points, index)}
                                            className={`border border-[#04F7FF80] shadow-md shadow-[#04F7FF80] px-5 py-2 text-sm rounded-xl text-[#FE0101] ${lastSelectedButtons[index] === 'wrong' ? 'bg-red-400' : ''}`}
                                        >
                                            <i className="fa-solid fa-x mr-3"></i>Wrong
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='flex justify-center py-12'>
                        <button className='px-10 py-2 text-xl text-white font-bold rounded-2xl bg-gradient-to-r from-[#0087A5] to-[#0E2DA7]' onClick={() => { handleSubmit(); }}>Submit</button>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}