
import React, { useEffect, useState } from 'react';
import { useLocation, Link, NavLink } from 'react-router-dom';
import Moralis from 'moralis';
import Header from './Header';
import Footer from '../Footer';
import Confetti from 'react-dom-confetti';
import LoginHeader from './LoginHeader';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setRecruiterEmail, setSeekerEmail } from '../store';
import { ethers } from 'ethers';
import abi from './abi.json';

import Loader from '../loaderdemo';
import ErrorPage from '../please_wait_page';
import CryptoJS from 'crypto-js';
import { REACT_APP_RECRUITER_SECRETKEY, REACT_APP_MORALIS_APIKEY, REACT_APP_MORALIS_CHAIN, REACT_APP_MORALIS_NFT_ADDRESS } from '../Config'
import './seeker.css'

//import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

export default function ItemDetails() {
    const [nftItems, setNftItems] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);

    const [RecruiterWalletAddress, setRecruiterWalletAddress] = useState('');
    const [RecruiterOrganizationName, setOrganizationName] = useState('');
    const [RecruiterIndustryType, setIndustryType] = useState('');
    const [RecruiterWebsiteURL, setWebsiteURL] = useState('');
    const [token_id, setToken_id] = useState('');
    const [RecruiterAvatar, setRecruiterAvatar] = useState('');
    const [SeekerAvatar, setSeekerAvatar] = useState('');
    const [SeekerAmount, setSeekerAmount] = useState('');




    const [SeekerName, setSeekerName] = useState('');
    const [SeekerContractType, setSeekerContractType] = useState('');
    const [SeekerEmail, setSeekerEmail] = useState('');

    const [balance, setBalance] = useState(0);

    const [isPartypopup, setPartypopup] = useState(false);
    const [isCongrats, setCongrats] = useState(false);
    const [isCongrats2, setCongrats2] = useState(false);
    const [insufficientBalance, setInsufficientBalance] = useState(false);


    const [validationStatus, setValidationStatus] = useState(null);
    const [formattedSetNames, setFormattedSetNames] = useState([]);
    const [result, setResult] = useState('');
    const [status, setStatus] = useState('');

    const [loading, setLoading] = useState(false);

    const location = useLocation();

    const email = useSelector((state) => state.recruiter_email);
    // console.log("Email Logged In", email)

    const dispatch = useDispatch();


    const moralis_apiKey = REACT_APP_MORALIS_APIKEY;
    const moralis_chainId = REACT_APP_MORALIS_CHAIN;
    const moralis_nft_contract_address = REACT_APP_MORALIS_NFT_ADDRESS;
    //const moralis_token_contract_address = REACT_APP_MORALIS_TOKEN_ADDRESS;

    // const moralis_apiKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImYwOTAzODlmLWE3ZjItNGZhZS1iNDAxLWE5YWI3YzA3NTBjMCIsIm9yZ0lkIjoiMzk0Mjc5IiwidXNlcklkIjoiNDA1MTQ0IiwidHlwZUlkIjoiZTQ4MjYxNjMtZTg4NS00NmU4LWEwNDEtYTFhMzhjN2Q0OTUxIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTcwNDcwMTgsImV4cCI6NDg3MjgwNzAxOH0.KMmhpeBrILB5HEOPd6oWS-1h2rgAMPrGsM659oyDnrE";
    // const moralis_chainId = "0x13882";
    // const moralis_nft_contract_address = "0x202b138730602a87b69b89Ee9eA3E2ad48d8D7ec";
    // const moralis_token_contract_address = "0x1872a0d0143a3Da810ce77E4f5C32f8D02d856e8";

    // const moralis_token_contract_address = "0x497c3A77DC4604d91531f9667FC839131DA8e0a1"

    const fetchData = async (cursor = null) => {
        // const params = new URLSearchParams(location.search);
        // const tokenId = params.get('tokenId');

        const params = new URLSearchParams(location.search);
        const encryptedTokenId = params.get('tokenId');

        // Decrypt the tokenId

        // const Recruiter_secretKey = "Recruiter@20#31%456&$#234asd3485690567@@#$%#%#$@@*&^%$E$#$%#";

        const Recruiter_secretKey = REACT_APP_RECRUITER_SECRETKEY;
        let bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedTokenId), Recruiter_secretKey);
        const tokenId = bytes.toString(CryptoJS.enc.Utf8);
        // console.log('Token ID:', tokenId);
        setLoading(true);
        setToken_id(tokenId);

        try {
            console.log('Fetching data...');
            await Moralis.start({
                apiKey: moralis_apiKey
            });

            let hasMore = true;
            let allResults = [];

            while (hasMore) {
            const response = await Moralis.EvmApi.nft.getContractNFTs({
                "chain": moralis_chainId,
                "format": "decimal",
                "address": moralis_nft_contract_address,
                cursor: cursor 
            });

            allResults = [...allResults, ...response.raw.result];


            if (response.raw.cursor) {
                cursor = response.raw.cursor;
            } else {
                hasMore = false; // No more data to fetch
            }
            const metadata = JSON.parse(response.raw.result[0].metadata);
            const ipfsImage = metadata.image.split('://')[1];
            //   console.log('IPFS Image:', ipfsImage);
            const temp = response.raw.result
            //  console.log('Response:', response.raw.result);
            setNftItems(response.raw.result);


            const filteredItems = tokenId
                ? temp.filter(item => item.token_id === tokenId)
                : [];
            //    console.log('Filtered Items:', filteredItems);


            setItems(filteredItems);

            if (filteredItems.length > 0) {
                const metadataString = filteredItems[0].metadata;
                const metadata = JSON.parse(metadataString);

                const name = metadata.name;

                const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');
                // console.log('contract_type', contract_typeAttribute ? contract_typeAttribute.value : 'Not specified');
                const contract_type = contract_typeAttribute.value;


                const email_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'seeker_email');
                // console.log('contract_type', contract_typeAttribute ? contract_typeAttribute.value : 'Not specified');
                const seeker_email = email_typeAttribute.value;


                setSeekerName(name);
                setSeekerContractType(contract_type);
                setSeekerEmail(seeker_email);
                //    console.log('Name:11111', name);

            } else {
                //   console.log('No matching items found.');
                //setLoading(false);
            }
        }
            //console.log("nameeeeeee", metadata.name);

            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            // if (error && error.code === 401) {
            //     setLoading(true); // Show loader only for 401 error
            // } else {
            //     setError(error);
            //     setLoading(false);
            // }
            setLoading(false);
        }
    };



    const getImageFromMetadata = (metadata) => {
        try {
            const parsedMetadata = JSON.parse(metadata);
            return parsedMetadata.image || "images/default-image.jpg";
        } catch (error) {
            console.error("Error parsing metadata:", error);
            return "images/default-image.jpg";
        }
    };

    const handleButtonClick = async (tokenId) => {
        try {
            handlePartypopup()
            const selectedItem = items.find(item => item.token_id === tokenId);

            if (!selectedItem) {
                console.error('Selected item not found.');
                // toast.error("Selected item not found.")

                return;
            }
            const itemName = selectedItem.metadata.name;
            await sendToRecruiterDatabase(email, selectedItem, itemName);
            //    console.log('Data sent to recruiter database.');
            //   console.log('2222222222', sendToRecruiterDatabase);


        } catch (error) {
            console.error('Error handling button click:', error);
        }
    };



    const sendToRecruiterDatabase = async (email, selectedItem, itemName) => {
        try {
            // Make an HTTP request to send data to the recruiter database
            const response = await axios.post('https://ex.d5art.com/api/store_buy_seeker_nft', {
                email: email,
                selectedItem: selectedItem,
                itemName: itemName,
            });

            // Check the response and handle accordingly
            if (response.data.status === 'success') {
                //    console.log('Data sent to recruiter database successfully.');
            } else {
                //   console.log('Failed to send data to recruiter database.');
            }
        } catch (error) {
            console.error('Error sending data to recruiter database:', error);
        }
    };


    // const handleButtonClick = (tokenId) => {

    //     const newPath = `/UserDetails?tokenId=${tokenId}`;
    //     window.location.href = newPath;
    // };
    //  onClick={() => { handleButtonClick(item.token_id) && handlePartypopup() }}

    useEffect(() => {
        const fetchDataAndItem = async () => {
            await fetchData();
        };
        fetchDataAndItem();
    }, []);






    // check balance

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ex.d5art.com/api/fetch_recruiter_transactions?recruiter_email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log('fetch_seeker_transactions', data.data);

                if (response.ok) {
                    const transactions = data.data;

                    const creditedTransactions = data.data.filter(item => item.status === 'Credited');
                    const debitedTransactions = data.data.filter(item => item.status === 'Debited');

                    // Sum the amounts for both credited and debited transactions
                    const totalCredited = creditedTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount || 0), 0);
                    const totalDebited = debitedTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount || 0), 0);

                    // -------  Calculate balance  -------
                    const balance = totalCredited - totalDebited;

                    setBalance(balance);

                    console.log("balance", balance);

                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);


    // buy Asset 


    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/fetch_wallet_from_recruiter?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                //  console.log('5555', data.data[0].avatar);


                if (response.ok) {

                    setRecruiterWalletAddress(data.data[0].walletaddress);
                    setOrganizationName(data.data[0].organizationName);
                    setIndustryType(data.data[0].industryType);
                    setWebsiteURL(data.data[0].websiteURL);
                    setRecruiterAvatar(data.data[0].avatar);

                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);


    useEffect(() => {
        const fetchData1 = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${SeekerEmail}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                //   console.log('555577', data.data[0]);

                //   console.log('555577', data.data[0].amount);


                if (response.ok) {

                    setSeekerAmount(data.data[0].amount)
                    setSeekerAvatar(data.data[0].avatar);

                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (SeekerEmail) {
            fetchData1();
        }
    }, [SeekerEmail]);



    const [isLoading, setIsLoading] = useState(false);


    const handleButtonClick1 = async () => {
        try {
            //const RecruiterWalletAddress = "0xe28900C2549b2E167e7414182D176E0fcF3DA5a8";
            //const RecruiterWalletAddress = "0x787eCEE25B884b4116a5ABe9A063B70c08a7aA13";  


            if (balance <= SeekerAmount) {
                toast.error("Please check the balance ");
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                return;
            }


            setIsLoading(true);
            const address = RecruiterWalletAddress;



            const response = await fetch('https://ex.d5art.com/api/Generating_Sign', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    RecruiterWalletAddress,
                    token_id,

                    email,
                    address,
                    SeekerName,
                    SeekerAmount,

                }),

            });
            const responseBody = await response.json();

            //  console.log('response body:', responseBody);
            if (response.ok) {
                //   console.log('You bought the NFT');
                setPartypopup(true);
                handleButtonClick2();

                handleCongrats(); handleCongrats2();

                // setTimeout(() => {
                //     setCongrats(true);
                // }, 20000);
            } else {
                console.error('Error updating data:', response.statusText);
                //toast.error(`NFT Already Purchased. ${response.statusText}`);
                // toast.error(`NFT Already Purchased `);
                toast.error(responseBody.error || 'An error occurred.');
                setCongrats(false);


                // setCongrats2(true);
            }


        } catch (error) {
            console.error('Error updating data:', error);
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(error.response.data.error);
            } else {
                toast.error('An error occurred. Please try again.');
            }
            toast.error('Failed to buy the NFT. Check whether you have D5art Tokens in your Wallet');

            //setCongrats2(true);
        } finally {
            setIsLoading(false);
        }
    };



    const handleButtonClick2 = async () => {
        try {

            // const tokens = 50;
            const tokens = SeekerAmount;
            console.log(tokens, "tokens")
            const seeker_email = SeekerEmail;
            // console.log("23423423423",seeker_email)

            const response = await fetch('https://ex.d5art.com/api/RecruiterMyCart', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    RecruiterOrganizationName,
                    RecruiterIndustryType,
                    RecruiterWebsiteURL,
                    SeekerName,
                    SeekerContractType,
                    tokens,
                    seeker_email,
                    token_id,
                    SeekerAvatar,
                    RecruiterAvatar,
                    RecruiterWalletAddress
                }),

            });
            const responseBody = await response.json();

            //  console.log('response body:', responseBody);
            if (response.ok) {
                //   console.log('Updated successfull.....');
                setTimeout(() => {
                    setCongrats(true);
                }, 20000);

            } else {
                console.error('Error updating data:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };






    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    const handlePartypopup = () => {
        //  console.log("balance, SeekerAmount", balance, SeekerAmount)
        if (balance >= SeekerAmount) {
            setPartypopup(!isPartypopup);

        } else {
            //   console.log("Insufficient balance");
            setInsufficientBalance(true);
        }
    }

    const handleCongrats = () => {
        setCongrats(!isCongrats)
    }

    const handleCongrats2 = () => {
        setCongrats2(!isCongrats2)
    }

    const containerStyles = {
        width: '100%',
        paddingTop: '10px',
        color: 'white',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const [windowDimension, setWindowDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [Btn, setBtn] = useState(false);

    const detectSize = () => {
        setWindowDimension({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    const handleBtnpop = () => {
        setBtn(!Btn);
    };

    // Effect for handling resize events
    useEffect(() => {
        const handleResize = () => {
            detectSize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array


    const confettiConfig = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 70,
        dragFriction: 0.12,
        duration: 6000,
        stagger: 3,
        width: '10px',
        height: '10px',
        perspective: '500px',
        colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
    };


    const handleGetValidationStatus = async () => {
        try {
            const response = await axios.post('https://ex.d5art.com/api/getValidationStatusByEmail', { email: SeekerEmail });
            const data = response.data;
            setValidationStatus(data);


            const formattedNames = data.map(entry => entry.Set_name.substring(11));
            const results = data.map(entry => entry.result);
            const statuses = data.map(entry => entry.status);
            //   console.log("response", response)
            setFormattedSetNames(formattedNames);
            //  console.log("formattedNames", formattedNames)

            setResult(results);
            //  console.log("results", result)
            setStatus(statuses);
            //  console.log("status", status)

        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        if (SeekerEmail) {
            handleGetValidationStatus();
        }
    }, [SeekerEmail]);



    const [hoveredCourse, setHoveredCourse] = useState(null);

    const handleMouseEnter = (courseName) => {
        setHoveredCourse(courseName);
    };

    const handleMouseLeave = () => {
        setHoveredCourse(null);
    };


    // check the ownership wheather the profile is purched or not

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(`https://ex.d5art.com/api/checkOwnership?tokenID=${token_id}`);
    
    //             if (!response.ok) {
    //                 const errorData = await response.json();
    //                 throw new Error(`Request failed with status: ${response.status} - ${errorData.error}`);
    //             }
    
    //             const data = await response.json();
    //             console.log('Ownership data:', data);
    
    //             if (data.success) {
    //                 console.log('Token is owned by the admin');
    //             } else {
    //                 console.log('Token ownership check failed:', data.error);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching data:', error.message);
    //         }
    //     };
    
    //     if (token_id) {
    //         fetchData();
    //     }
    // }, [token_id]);
    
    
    
      

    if (loading) {
        return <Loader />;
    }


    return (
        <div className='font-poppins  bg-[#101010] backdrop-blur-sm '>




            {/* Conditional rendering for loader and blank page when their is no response from moralis */}
            {
                // loading ? (
                //     <Loader />
                // ) : 
                // items.length === 0 ? (
                //     <ErrorPage/>
                //     // <div className="flex justify-center bg-[#121212] items-center h-screen">
                //     //     <p className="text-white">Please wait...</p>
                //     // </div>
                // ) : 
                (



                    items.map((item, index) => {
                        const metadata = JSON.parse(item.metadata);
                        const ipfsImage = metadata.image.split('://')[1];

                        // const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'Skills');
                        // const skills = skillsAttributes.map(attr => attr.value);
                        // console.log('skills', skills);

                        const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'skills_percentage');
                        const skills = skillsAttributes.map(attr => attr.value);
                        const PriceAttributes = metadata.attributes.filter(attr => attr.trait_type === 'price');
                        const price = PriceAttributes.map(attr => attr.value);
                        // console.log('skills', skills);

                        const prefered_rollsAttribute = metadata.attributes.filter(attr => attr.trait_type === 'prefered_roll');
                        const prefered_rolls = prefered_rollsAttribute.map(attr => attr.value);
                        //  console.log('prefered_roll', prefered_rolls);




                        const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');
                        //console.log('contract_type', contract_typeAttribute ? contract_typeAttribute.value : 'Not specified');


                        const experience_roleAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_role');
                        const experience_roles = experience_roleAttributes.map(attr => attr.value);
                        //console.log('experience_roles', experience_roles);

                        const experience_fromAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_from');
                        const experience_froms = experience_fromAttributes.map(attr => attr.value);
                        //console.log('experience_from', experience_froms);

                        const experience_toAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_to');
                        const experience_tos = experience_toAttributes.map(attr => attr.value);
                        //console.log('experience_to', experience_tos);

                        const projectAttributes = metadata.attributes.filter(attr => attr.trait_type === 'project');
                        const project = projectAttributes.map(attr => attr.value);
                        // console.log('projectAttributes', project);


                        const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'experience');
                        const experience = experience_Attributes.map(attr => attr.value);
                        //  console.log('experience123123', experience);



                        const project_languageAttributes = metadata.attributes.filter(attr => attr.trait_type === 'project_language_used');
                        const project_language_used = project_languageAttributes.map(attr => attr.value);
                        // console.log('project_language_used', project_language_used);

                        const slicedName = metadata && typeof metadata.name === 'string' ? metadata.name.slice(0, 10) : '';


                        const platform_skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'platform_skills');
                        const platform_skills = platform_skillsAttributes.map(attr => attr.value);

                        const platform_certificatesAttributes = metadata.attributes.filter(attr => attr.trait_type === 'platform_certificates');
                        const platform_certificates = platform_certificatesAttributes.map(attr => attr.value);

                        const getCourseScore = (courseName) => {

                            const flattenedCertificates = platform_certificates.flat();

                            console.log("Flattened certificates:", flattenedCertificates);


                            const certificate = flattenedCertificates.find(cert => cert.course_name === courseName);
                            console.log("certificate....", certificate);

                            return certificate ? certificate.score : null;
                        };





                        //  console.log("platform_skills", platform_skills)
                        console.log("platform_certificates", platform_certificates)




                        const combinedSkills = [...skillsAttributes.map(attr => attr.value), ...platform_skillsAttributes.map(attr => attr.value)];

                        console.log("combined skills", combinedSkills);

                        const normalSkills = [...skillsAttributes.map(attr => attr.value)];
                        const platformSkills = [...platform_skillsAttributes.map(attr => attr.value)];

                        const groupedPlatformSkills = platformSkills.reduce((acc, skill) => {
                            const skillDetails = typeof skill === 'string' ? JSON.parse(skill) : skill;

                            skillDetails.forEach((item) => {
                                const courseName = item.course || 'Unknown Course';
                                if (!acc[courseName]) {
                                    acc[courseName] = [];
                                }
                                acc[courseName].push(item);
                            });
                            return acc;
                        }, {});


                        return (
                            <React.Fragment key={index}>
                                <LoginHeader />
                                <Toaster />

                                {/* {loading === true ?
          <Loader />
          : ''
        } */}

                                <div className='Content1 pb-10' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                                    <div className='flex justify-center'>
                                        <h1 className='text-center text-3xl font-bold'
                                            style={{
                                                backgroundClip: 'text',
                                                WebkitBackgroundClip: 'text',
                                                color: 'transparent',
                                                backgroundImage: 'linear-gradient(to right, #31989B, #4A5CA4)',
                                            }}>Skill Realm</h1>
                                    </div>
                                    <div className='flex justify-center'>
                                        <div style={containerStyles}>
                                            <div className='flex justify-center'>
                                            </div>
                                            <div className='relative mt-5 lg:mt-5'>
                                                <div className='lg:flex w-[90%] mx-auto border border-[#0EA4A9] bg-[#373737]/25 shadow-md rounded-xl'>
                                                    <div className='lg:w-[40%] flex pt-5 lg:pt-0'>
                                                        <div class="md:h-[100%] w-full">
                                                            <div key={index} className={`h-[85%] w-[80%] mx-auto flex items-center justify-center bg-gradient-to-b from-[#04F7FF52] `}>
                                                                <img src={getImageFromMetadata(item.metadata)} class="m-auto" />
                                                            </div>
                                                            <div className='h-[15%] mt-5 lg:mt-0 flex justify-center items-center gap-5'>
                                                                <p className='flex items-center gap-2'><span><img src='/assets/coin.png'></img></span><span className='font-bold text-yellow-500'>{SeekerAmount}</span> D⁵Art tokens</p>
                                                                <button
                                                                    key={index}
                                                                    onClick={() => { handlePartypopup(); }}
                                                                    className="group relative cursor-pointer overflow-hidden whitespace-nowrap px-5 py-4 text-white [background:var(--bg)] [border-radius:var(--radius)] transition-all duration-300 hover:scale-105 flex justify-center"
                                                                    style={{
                                                                        "--spread": "90deg",
                                                                        "--shimmer-color": "#ffffff",
                                                                        "--radius": "100px",
                                                                        "--speed": "1.5s",
                                                                        "--cut": "0.1em",
                                                                        "--bg": "radial-gradient(ellipse 80% 50% at 50% 120%, #ffffff50, rgba(18, 70, 75)",
                                                                    }}
                                                                >
                                                                    <div class="absolute inset-0 overflow-hidden">
                                                                        <div class="absolute inset-[-100%] rotate-gradient">
                                                                            <div class="absolute inset-0 [background:conic-gradient(from_calc(270deg-(var(--spread)*0.5)),transparent_0,hsl(0_0%_100%/1)_var(--spread),transparent_var(--spread))]"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="absolute [background:var(--bg)] [border-radius:var(--radius)] [inset:var(--cut)]"></div>
                                                                    <span class="z-10 w-32  bg-gradient-to-b from-black from-30% to-gray-300/80 bg-clip-text text-center font-semibold leading-none tracking-tight text-white">Buy</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="nft-image-wrapper">
                                                            {/* <img src="/avatar/view-3d-businessman.png" class="image-autosize mx-auto img-fluid img-rounded mb-sm-30 resized-image " alt="" /> */}
                                                        </div>
                                                    </div>
                                                    <div className='lg:w-[60%] relative'>
                                                        <div className='p-5 w-[100%]'>
                                                            <h1 className='font-bold my-3 text-3xl text-[#FFBA00] font-play'>D5ART ID:  <span className='text-white' title={metadata.name}> {metadata.name && metadata.name.length > 0 ? metadata.name.slice(0, 10) + '' : metadata.name}</span></h1>
                                                            <div className='flex items-end justify-start px-5 mb-3'>
                                                                <p className='border-[2px] border-[#04F7FF80] rounded-2xl px-5 py-1 font-bold'>{contract_typeAttribute ? contract_typeAttribute.value : 'Not specified'}</p>
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <h1 className='uppercase text-xl text-[#FFBA00] font-bold'>About:</h1>
                                                                    <p className='px-5 my-3 h-[80px] overflow-hidden overflow-y-auto'>{metadata.description} </p>
                                                                </div>
                                                            </div>
                                                            <p className='text-[#FFBA00] font-bold uppercase text-xl'>Attributes:</p>
                                                            <div className='mt-2 skill1 h-[310px] overflow-hidden overflow-y-auto px-5'>
                                                                {/* Render normalSkills */}
                                                                {normalSkills.map((skill, index) => {
                                                                    const skillDetails = typeof skill === 'string' ? JSON.parse(skill) : skill;

                                                                    if (!Array.isArray(skillDetails)) {
                                                                        console.warn(`Expected skillDetails to be an array at index ${index}`, skillDetails);
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <div key={index}>
                                                                            {skillDetails.map((item, subIndex) => {
                                                                                const skillName = item.skill || item.name;
                                                                                const percentage = item.percentage || item.points;

                                                                                if (!skillName) {
                                                                                    console.warn(`Undefined skillName at subIndex ${subIndex}`, item);
                                                                                    return null;
                                                                                }

                                                                                const matchedSkill = formattedSetNames.find(name => name === skillName);
                                                                                const shouldDisplayImage = matchedSkill && (result[index] === 'PASS' || result[index] === null);

                                                                                return (
                                                                                    <div key={subIndex}>
                                                                                        <div className='flex justify-between'>
                                                                                            <div>
                                                                                                <p className='font-semibold mt-2'>
                                                                                                    {skillName}{' '}
                                                                                                    <span className='text-[#0EA4A9]'>
                                                                                                        - {percentage}{' '}
                                                                                                        {item.percentage ? '%' : '0%'}
                                                                                                    </span>
                                                                                                </p>
                                                                                            </div>

                                                                                            {/* Conditional image display */}
                                                                                            {shouldDisplayImage && (
                                                                                                <div>
                                                                                                    <img src='/assets/D5ART_Fav icon.png' alt='Skill logo' />
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        {/* Progress bar */}
                                                                                        <div className='px-3 py-2'>
                                                                                            <div className='h-2 w-full bg-gray-700 rounded'>
                                                                                                <div
                                                                                                    className='h-2 rounded bg-gradient-to-r from-[#13BDB3] to-[#0E14A9]'
                                                                                                    style={{ width: `${percentage|| 0}%` }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        {/*
                                                                                    <div className='flex justify-center py-4'>
                                                                                        <div>
                                                                                            <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
                                                                                                <div className='p-1.5 rounded-full bg-white'></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='h-[1px] w-full bg-white'></div>
                                                                                        <div>
                                                                                            <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
                                                                                                <div className='p-1.5 rounded-full bg-white'></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>*/}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    );
                                                                })}

                                                                {/* Render groupedPlatformSkills */}
                                                                {Object.keys(groupedPlatformSkills).map((courseName, index) => {
                                                                    const courseScore = getCourseScore(courseName);
                                                                    console.log("courseScore", courseScore)

                                                                    return (
                                                                        <div key={index}>
                                                                            <div className='flex justify-between'>
                                                                                <p
                                                                                    className='font-semibold mt-2 cursor-pointer '
                                                                                    onMouseEnter={() => handleMouseEnter(courseName)}
                                                                                    onMouseLeave={handleMouseLeave}
                                                                                >
                                                                                    {courseName}
                                                                                    <span className='text-[#0EA4A9]'> - {" "}
                                                                                        {courseScore ? `${courseScore}%` : 'N/A'} {/* Display the dynamic score */}
                                                                                    </span>
                                                                                </p>

                                                                                {/* Optionally display the image and progress bar */}
                                                                                <div>
                                                                                    <img src='/assets/D5ART_Fav icon.png' alt='Course logo' />
                                                                                </div>
                                                                            </div>
                                                                            <div className='px-3 py-2'>
                                                                                <div className='h-2 w-full bg-gray-700 rounded'>
                                                                                    <div
                                                                                        className='h-2 rounded bg-gradient-to-r from-[#13BDB3] to-[#0E14A9]'
                                                                                        style={{ width: `${courseScore || 0}%` }}  // Use score as width
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {/*
                                                                            <div className='flex justify-center py-4'>
                                                                                <div>
                                                                                    <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
                                                                                        <div className='p-1.5 rounded-full bg-white'></div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='h-[1px] w-full bg-white'></div>
                                                                                <div>
                                                                                    <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
                                                                                        <div className='p-1.5 rounded-full bg-white'></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            */}
                                                                            {/* Conditionally display platform skills on hover */}
                                                                            {hoveredCourse === courseName && (
                                                                                <div>
                                                                                    {groupedPlatformSkills[courseName].map((item, subIndex) => {
                                                                                        const skillName = item.skill || item.name;
                                                                                        const percentage = item.percentage || item.skillPoints;

                                                                                        if (!skillName) {
                                                                                            console.warn(`Undefined platformSkillName at subIndex ${subIndex}`, item);
                                                                                            return null;
                                                                                        }

                                                                                        const matchedSkill = formattedSetNames.find(name => name === skillName);
                                                                                        const shouldDisplayImage = matchedSkill && (result[subIndex] === 'PASS' || result[subIndex] === null);

                                                                                        return (
                                                                                            <div key={subIndex}>
                                                                                                <div className='flex justify-between '>
                                                                                                    <div>
                                                                                                        <p className='font-semibold mt-2'>
                                                                                                            {skillName}{' '}
                                                                                                            <span className='text-[#0EA4A9]'>
                                                                                                                - {percentage}{' '}
                                                                                                                {item.percentage ? '%' : 'points'}
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </div>

                                                                                                    {/* Display image if condition matches */}
                                                                                                    {shouldDisplayImage && (
                                                                                                        <div className='flex justify-between'>
                                                                                                            <img src='/assets/D5ART_Fav icon.png' alt='Skill logo' />
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>

                                                                                                {/* Progress bar */}
                                                                                                {/* <div className='px-3 py-2'>
                                                                                                <div className='h-2 w-full bg-gray-700 rounded'>
                                                                                                    <div
                                                                                                        className='h-2 rounded bg-gradient-to-r from-[#13BDB3] to-[#0E14A9]'
                                                                                                        style={{ width: `${percentage}%` }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div> */}

                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <div className='flex items-center justify-end'>
                                                                <img src='/assets/D5ART_Fav icon.png' /> Indicates the skill is verified by D⁵Art </div>
                                                        </div>

                                                        <img src='/assets/StampCV.png' className='absolute w-[40%] right-7 top-12'></img>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>










                                    {isLoading && (
                                        // Show loading spinner while isLoading is true
                                        // <div className="flex justify-center items-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
                                        //     <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-gray-900"></div>
                                        // </div>

                                        <div className="flex justify-center items-center fixed inset-0 model-overlay flex justify-center items-center  w-full h-full bg-black bg-opacity-50 backdrop-blur-md z-50">
                                            <div class="card">
                                                <div class="loader">
                                                    <p>loading</p>
                                                    <div class="words font-bold">
                                                        <span class="word">Please Wait</span>
                                                        <span class="word">Processing</span>
                                                        <span class="word">your</span>
                                                        <span class="word">D5art Passport</span>
                                                        <span class="word">purchase</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}


                                    {isPartypopup && (
                                        <div className="z-10 w-full fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm ">
                                            <div className='w-[95%] lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                                                <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={handlePartypopup}></i></div>
                                                <div className='flex md:h-[17rem] 2xl:[20rem]'>
                                                    <div className='w-[40%]'>
                                                        <div >
                                                            <img src='/assets/avatar.png' className='md:absolute md:h-[22.5rem] md:-translate-y-[90px] md:-translate-x-16 2xl:-translate-x-0'></img>
                                                        </div>
                                                    </div>
                                                    <div className='w-[60%] pr-3 pb-3 md:pr-7 text-white grid content-around gap-2 md:gap-0'>
                                                        <p className='flex justify-between text-lg'><p>NFT: </p><span className='text-[#00F4FC]'>{slicedName}</span></p>
                                                        <p className='uppercase text-xs md:text-sm'>Please confirm the purchase of the D5art Passport. We are excited to proceed with enhancing our recruitment process.<br /> Thank you!</p>
                                                        <div className='flex flex-col md:flex-row justify-between'>
                                                            <div className='flex'>
                                                                <img src=' /assets/coin.png'></img><p className='mt-1 ml-2 font-play text-[#F29D38]'>{SeekerAmount} D5ART Tokens</p>
                                                            </div>
                                                            <div>
                                                                <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg' onClick={async () => { handleButtonClick(); await handleButtonClick1(); }} ><button className='bg-black px-4 text-sm py-1 rounded-lg'>BUY NOW</button></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {insufficientBalance && (
                                        <div className="z-10 w-full fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm ">

                                            <div className='w-[95%] lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                                                <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={() => setInsufficientBalance(false)}></i></div>
                                                <div className='flex md:h-[17rem] 2xl:[20rem]'>
                                                    <div className='w-[40%]'>
                                                        <div >
                                                            <img src='/assets/avatar.png' className='md:absolute md:h-[22.5rem] md:-translate-y-[90px] md:-translate-x-16 2xl:-translate-x-0'></img>
                                                        </div>
                                                    </div>
                                                    <div className='w-[60%] pr-3 pb-3 md:pr-7 text-white grid content-around gap-2 md:gap-0'>
                                                        <p className='flex justify-between text-lg'><p>Wallet Balance: </p><span className='text-[#00F4FC]'>{balance}</span></p>
                                                        <p className='uppercase text-xs md:text-sm'>Insufficient Balance! You need at least {SeekerAmount} DART Tokens.</p>
                                                        <div className='flex flex-col md:flex-row justify-between'>
                                                            <div className='flex'>
                                                                <img src=' /assets/coin.png'></img><p className='mt-1 ml-2 font-play text-[#F29D38]'>{SeekerAmount} DART Tokens</p>
                                                            </div>
                                                            <div>
                                                                <NavLink to='/RMS/Recruiter/RecruiterDashboard' ><button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg'><button className='bg-black px-4 text-sm py-1 rounded-lg'>Add Funds</button></button>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                    {isCongrats && (
                                        <div className="z-10 w-full lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-50 lg:backdrop-blur-sm ">
                                            <div className='lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                                                <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={handleCongrats} ></i></div>
                                                <div className='flex h-[17rem] 2xl:[20rem]'>
                                                    <div className='w-[40%]'>
                                                        <div >
                                                            <img src='/assets/avatar.png' className='absolute h-[22.5rem] -translate-y-[90px] -translate-x-12 2xl:-translate-x-0'></img>
                                                        </div>
                                                    </div>
                                                    <div className='w-[60%]  pr-7 text-white grid content-around'>
                                                        <p className='flex justify-center text-2xl font-bold'><span className='text-[#00F4FC]'>Congratulations</span></p>
                                                        <p className='text-sm text-center'>The NFT get purchased successfull kindly refer your cart to get access the profile.</p>
                                                        <div className='flex justify-center'>
                                                            <div>
                                                                <Link to="/RMS/Recruiter/RecruiterDashboard">
                                                                    <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg' onClick={handleBtnpop}>
                                                                        <button className='bg-black px-4 text-sm py-1 rounded-lg'>My Cart</button>
                                                                    </button>
                                                                </Link>
                                                                <Confetti active={Btn} config={confettiConfig} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* { !isCongrats && isCongrats2 && (
                                <div className="z-10 w-full lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-50 lg:backdrop-blur-sm ">
                                    <div className='lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                                        <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1'  onClick={handleCongrats2}></i></div>
                                        <div className='flex h-[17rem] 2xl:[20rem]'>
                                            <div className='w-[40%]'>
                                                <div >
                                                    <img src='/assets/avatar.png' className='absolute h-[22.5rem] -translate-y-[90px] -translate-x-12 2xl:-translate-x-0'></img>
                                                </div>
                                            </div>
                                            <div className='w-[60%]  pr-7 text-white grid content-around'>
                                                <p className='flex justify-center text-2xl font-bold'><span className='text-[#00F4FC]'>Oops!! Check Your Wallet Balance  </span></p>
                                                <p className='text-sm text-center'> Failed to buy the NFT. Check whether you have FABC Tokens in your Wallet.
                                                </p>
                                                <div className='flex justify-center'>
                                                    <div>
                                                        <Link to="/RMS/Recruiter/RecruiterDashboard">
                                                            <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg' onClick={handleBtnpop}>
                                                                <button className='bg-black px-4 text-sm py-1 rounded-lg'>My Wallet</button>
                                                            </button>
                                                        </Link>
                                                        <Confetti active={Btn} config={confettiConfig} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}  */}








                                    {/*
                                    <div className='flex justify-center text-white mt-10 md:mt-0'>
                                        <div className='w-[90%] lg:flex'>
                                            <div className='lg:w-[65%]'>
                                                <h1 className='uppercase text-[#646464] text-2xl font-bold'>Summary:</h1>
                                                <p className='p-3 uppercase'></p>
                                            </div>

                                            <div className='lg:w-[45%] 2xl:pl-16'>
                                                <h1 className='uppercase text-[#646464] text-2xl font-bold'>Preferred Role:</h1>
                                                <div className='flex flex-col md:flex-row gap-3 md:gap-5 p-5'>
                                                    {prefered_rollsAttribute.map((prefered_roll, index) => {
                                                        if (prefered_roll.value) {
                                                            try {

                                                                const preferedRoles = JSON.parse(prefered_roll.value);
                                                                return preferedRoles.map((preferedRole, roleIndex) => (
                                                                    <div key={index + '-' + roleIndex}>
                                                                        <p className='uppercase px-3 py-2 md:py-1 text-center bg-gray-500 rounded-full'>{preferedRole}</p>
                                                                    </div>
                                                                ));
                                                            } catch (error) {
                                                                console.error('Error parsing preferred role data:', error);
                                                                return null;
                                                            }
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                    */}

                                    <div className='flex flex-col lg:flex-row w-[90%] gap-5 mx-auto text-white my-10'>
                                        <div className='lg:w-[40%] '>
                                            <p className='text-[#FFBA00] font-bold uppercase text-xl mb-3'>Experience:</p>
                                            <div className='h-full border border-[#08AE21] rounded-xl p-5'>

                                                <div className='h-full'>
                                                    {experience.length === 0 || experience.every(exp => {
                                                        const experienceDetails = JSON.parse(exp);
                                                        return !experienceDetails || !experienceDetails.companyname || !experienceDetails.from || !experienceDetails.to || !experienceDetails.role ||
                                                            experienceDetails.companyname.trim() === '' || experienceDetails.from.trim() === '' || experienceDetails.to.trim() === '' || experienceDetails.role.trim() === '';
                                                    }) ? (
                                                        <div className='flex justify-center items-center h-full'>
                                                            <img src="/assets/No Experience.png" alt="No Experience" className=" md:w-[60%]" />
                                                        </div>
                                                    ) : (
                                                        experience.map((exp, index) => {
                                                            const experienceDetails = JSON.parse(exp);
                                                            if (experienceDetails && experienceDetails.companyname && experienceDetails.from && experienceDetails.to && experienceDetails.role &&
                                                                experienceDetails.companyname.trim() !== '' && experienceDetails.from.trim() !== '' && experienceDetails.to.trim() !== '' && experienceDetails.role.trim() !== '') {
                                                                return (
                                                                    <div key={index} className='my-3 bg-black/65 rounded-xl px-3 py-2'>
                                                                        <div>
                                                                            <p className='flex justify-between px-3'>
                                                                                <p className='font-bold'>{experienceDetails.role}</p>
                                                                                <p className='mt-2 text-[#36A90E]'>Verified</p>
                                                                            </p>
                                                                            <p className='flex justify-between px-3 mt-2'>
                                                                                <p className='font-bold text-[#0EA4A9] text-sm'>{experienceDetails.companyname}</p>
                                                                                <p className='text-xs font-bold'>{experienceDetails.from} - {experienceDetails.to}</p>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return null; // Skip rendering if any field is empty
                                                            }
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='lg:w-[60%] '>
                                            <h1 className='uppercase text-[#FFBA00] text-xl font-bold mb-3'>Projects:</h1>
                                            <div className='h-full border border-[#8C11A1] rounded-xl p-5'>
                                                <div className='mt-7 flex flex-col md:flex-row  gap-5 overflow-hidden overflow-x-auto'>
                                                    {project.length === 0 || project.every(p => !p || typeof p !== 'string' || !p.trim()) ? (
                                                        <div>
                                                            <img src="/assets/no projects.png" alt="no projects" className="mx-auto md:w-[40%]" />
                                                        </div>
                                                    ) : (
                                                        project.map((project_name, index) => (
                                                            (project_name && typeof project_name === 'string' && project_name.trim() !== '') && (
                                                                <div key={index} className='project_display bg-gradient-to-tl from-[#0EA4A9] to-[#0E2DA7] p-[2px] rounded-3xl flex-shrink-0'>
                                                                    <div className='bg-[#101010] p-5 h-full rounded-3xl w-full'>
                                                                        <p className='text-center py-3'>{project_name}</p>
                                                                        <div className='grid grid-cols-3 gap-3 pt-7 pb-5 text-xs justify-center items-center'>
                                                                            {project_language_used[index] && JSON.parse(project_language_used[index]).map((language, subIndex) => (
                                                                                <p key={subIndex} className='py-1 rounded-lg border text-center'>{language}</p>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ))
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </React.Fragment>
                        );
                    })
                )}
            <Footer />
            <style>
                {`
              
.card {
  /* color used to softly clip top and bottom of the .words container */
  --bg-color: #212121;
  background-color: var(--bg-color);
  padding: 1rem 2rem;
  border-radius: 1.25rem;
  
}
.loader {
  color: rgb(124, 124, 124);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 25px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 40px;
  padding: 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
}

.words {
  overflow: hidden;
  position: relative;
}
.words::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    var(--bg-color) 10%,
    transparent 30%,
    transparent 70%,
    var(--bg-color) 90%
  );
  z-index: 20;
}

.word {
  display: block;
  height: 100%;
  padding-left: 6px;
  color: #F29D38;
  animation: spin_4991 6s infinite;
}

@keyframes spin_4991 {
  10% {
    -webkit-transform: translateY(-102%);
    transform: translateY(-102%);
  }

  25% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  35% {
    -webkit-transform: translateY(-202%);
    transform: translateY(-202%);
  }

  50% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }

  60% {
    -webkit-transform: translateY(-302%);
    transform: translateY(-302%);
  }

  75% {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  85% {
    -webkit-transform: translateY(-402%);
    transform: translateY(-402%);
  }

  100% {
    -webkit-transform: translateY(-400%);
    transform: translateY(-400%);
  }
}
`}
            </style>
        </div>

    )
}