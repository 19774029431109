import React from 'react';

const ButtonComponent = ({ label, bgColor = 'linear-gradient(to right, #31989B, #4A5CA4)' }) => {
  return (
    <button
      className="group relative cursor-pointer overflow-hidden whitespace-nowrap px-5 py-4 text-white [background:var(--bg)] [border-radius:var(--radius)] transition-all duration-300 hover:scale-105 flex justify-center"
      style={{
        "--spread": "90deg",
        "--shimmer-color": "#ffffff",
        "--radius": "100px",
        "--speed": "1.5s",
        "--cut": "0.1em",
        "--bg": bgColor, // Apply the new gradient here
      }}
    >
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-[-100%] rotate-gradient">
          <div className="absolute inset-0 [background:conic-gradient(from_calc(270deg-(var(--spread)*0.5)),transparent_0,hsl(0_0%_100%/1)_var(--spread),transparent_var(--spread))]"></div>
        </div>
      </div>
      <div className="absolute [background:var(--bg)] [border-radius:var(--radius)] [inset:var(--cut)]"></div>
      <span className="z-10 w-32 text-base bg-gradient-to-b from-black from-30% to-gray-300/80 bg-clip-text text-center font-semibold leading-none tracking-tight text-white">
        {label} 
      </span>
    </button>
  );
};

export default ButtonComponent;
