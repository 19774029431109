// import React from 'react';
// import ReactApexChart from 'react-apexcharts';

// class ApexChart extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       series: [
//         { name: 'Credit', data: [10, 15, 12, 10, 5, 15] },
//         { name: 'Debit', data: [25, 35, 30, 30, 27, 40] },
//       ],
//       options: {
//         chart: {
//           type: 'bar',
//           height: 250,
//         },
//         plotOptions: {
//           bar: {
//             horizontal: false,
//             columnWidth: '55%',
//             endingShape: 'rounded',
//             borderRadius: 5,
//           },
//         },
//         dataLabels: {
//           enabled: false,
//         },
//         stroke: {
//           show: true,
//           width: 2,
//           colors: ['transparent'],
//         },
//         xaxis: {
//           categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
//         },
//         yaxis: {
//           title: {
//             text: '$ (thousands)',
//           },
//         },
//         fill: {
//           opacity: 1,
//         },
//         tooltip: {
//           y: {
//             formatter: function (val) {
//               return `$ ${val} thousands`;
//             },
//           },
//         },
//         colors: ['#0EA4A9', '#0E2DA7'],
//       },
//     };
//   }

//   render() {
//     return (
//       <div className='w-[20rem] h-[20rem]'>
//         <div id="chart">
//           <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
//         </div>
//         <style>
//           {`
           
//             .apexcharts-menu-item-text {
//               fill: black !important;
//             }
//           `}
//         </style>
//       </div>
//     );
//   }
// }

// export default ApexChart;



import React from 'react';
import ReactApexChart from 'react-apexcharts';

class ApexChart extends React.Component {

  constructor(props) {
    super(props);
    console.log('Props:', props);
    this.state = {
      series: [
        { name: 'Credit', data: props.creditData },
        { name: 'Debit', data: props.debitData },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 250,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: props.months,
          labels: {
            style: {
              colors: '#FFFFFF',
            },
          },
          title: {
            style: {
              color: '#FFFFFF',
            },
          },
        },
        yaxis: {
          title: {
            text: 'D5ART Tokens',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            style: {
              colors: '#FFFFFF',
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val} Token`;
            },
          },
        },
        legend: {
          labels: {
            colors: '#FFFFFF', // White color for legend text
          },
        },
        colors: ['#0EA4A9', '#0E2DA7'],
      },
    };
  }
  render() {

    console.log()

    return (
      <div className='w-[20rem] h-[20rem]'>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
        </div>
        <style>
          {`
           
            .apexcharts-menu-item-text {
              fill: black !important;
            }
          `}
        </style>
      </div>
    );
  }
}

export default ApexChart;