import React, { useState } from 'react'
import Header from "../../Seeker/LoginHeader";
import Footer from "../../Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import { SERVER_URL } from '../../Config';
import toast, { Toaster } from "react-hot-toast";
import TeamMembers from './TeamMembers';







export default function Instruction() {
    const [teammembersopen, setTeamMembersopen] = useState(false);

    const navigateTeamProgess = () => {
        // window.location = "/jordan-event/team-members";
        setTeamMembersopen(!teammembersopen);
      };
    
    
    const navigateEventPage = () =>{
        window.location = ("/jordan-event");
    }
    const [isChecked, setIsChecked] = useState(false);
    const handleTakeAssessment = (e) => {
        if (!isChecked) {
            e.preventDefault(); // Prevent navigation
            toast.error("Please confirm that you have read and understood the instructions before proceeding.");
        }
    };
    return (
        <div className='bg-[#0F0F0F] min-h-screen bg-Fabcbg bg-cover overflow-hidden overflow-y-auto bg-center'>
            <Header />
            <Toaster/>
            <button className='mx-[5%] text-white flex items-center' onClick={navigateEventPage}>
            <FontAwesomeIcon icon={faChevronLeft} className='text-sm mr-2'/>
            Back
            </button>
        
            <div className=' pb-10 h-screen'>
                <div className='text-white w-[90%] mx-auto'>
                    <div className='text-center space-y-2 fade-in'>
                        <div className='flex justify-center'>
                            <h1 className='bg-gradient-to-r from-[#31989B] to-[#4A5CA4] text-transparent bg-clip-text text-3xl font-bold'>Events</h1>
                        </div>
                        <h2 className='font-bold text-xl'>Jordon Skill Summit</h2>
                        <h3 className='italic'>Dec 24 - Feb 25</h3>
                    </div>
                    <div class="flex flex-col lg:flex-row gap-8 lg:gap-12 items-center mt-7 fade-in">
                        <div className='w-[100%] lg:w-[60%]'>
                            <h1 className='font-bold text-2xl lg:text-3xl mb-2'>Pre-skill Assessment</h1>
                            <h2 class="text-xl font-bold mb-4">Instructions</h2>
                            <ol class="space-y-4 lg:text-sm 2xl:text-base">
                                <li>
                                    <span class="font-semibold">1. Find a Comfortable Setting:</span> Choose a quiet and comfortable environment where you can concentrate without interruptions for the duration of the assessment.
                                </li>
                                <li>
                                    <span class="font-semibold">2. Stay Relaxed and Authentic:</span> Answer honestly and authentically; there are no right or wrong answers. The assessment is designed to understand your natural tendencies and preferences.
                                </li>
                                <li>
                                    <span class="font-semibold">3. Avoid Overthinking:</span> Respond to each question based on your first instinct rather than overanalyzing. This ensures the truest reflection of your personality.
                                </li>
                                <li>
                                    <span class="font-semibold">4. Manage Your Time:</span> While there may not be strict time limits, ensure you allocate enough time to complete the assessment without feeling rushed.
                                </li>
                                <li>
                                    <span class="font-semibold">5. Be Prepared and Focused:</span> Make sure you’re well-rested and mentally prepared. Take a moment to relax before starting to ensure clarity and focus.
                                </li>
                            </ol>
                        </div>
                        <div className='w-[100%] lg:w-[40%]'>
                            <div>
                                <h3 class="text-lg text-center font-bold mb-2">Ready To Onboard</h3>
                                <div class="grid grid-cols-3 gap-4 text-center">
                                    <div class="flex flex-col items-center">
                                        <div class="w-10 h-10 flex items-center justify-center text-2xl text-[#02DC52] font-bold">1</div>
                                        <span class="mt-2 text-sm text-[#02DC52]">Register</span>
                                    </div>
                                    <div class="flex flex-col items-center">
                                        <div class="w-10 h-10 flex items-center justify-center text-2xl text-[#02DC52] font-bold">2</div>
                                        <span class="mt-2 text-sm text-[#02DC52]">Pre-Skill</span>
                                    </div>
                                    <div class="flex flex-col items-center">
                                        <div class="w-10 h-10 flex items-center justify-center text-2xl font-bold">3</div>
                                        <span class="mt-2 text-sm">Pitch Submission</span>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-10 lg:text-sm 2xl:text-base space-y-3">
                                <label class="flex items-center space-x-2">
                                    <input type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-700 rounded border-gray-600 "
                                     onChange={(e) => setIsChecked(e.target.checked)}/>
                                    <span>Please confirm that you have read and understood the instructions to proceed to the next step.</span>
                                </label>
                                <p><span className='font-bold'>Note:</span> This result won’t impact on Hackathon participation</p>
                            </div>

                            <div class="mt-6 flex justify-between">
                                <button class="px-4 py-2 border-2 border-[#5973CC] text-white rounded-lg "onClick= {navigateTeamProgess}>
                                    Team Progress
                                </button>
                                <NavLink to="/jordan-event/start-assessment" onClick={handleTakeAssessment} >
                                <button class="px-4 py-2 bg-[#5973CC] text-white rounded-lg ">
                                
                                    Take Assessment
                                </button>
                                </NavLink>
                            </div>

                            {teammembersopen && (
            <div className="fixed inset-0 flex justify-center items-center bg-black/30 backdrop-blur-md " >
              <TeamMembers onClose={navigateTeamProgess} /> 
              </div>
        )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
