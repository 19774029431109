import React, { useEffect, useState } from 'react';
// import Header from './Header';
import LoginHeader from './LoginHeader';
import { useSelector } from 'react-redux';
import { setRecruiterEmail } from '../store';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import CountUp from 'react-countup';
import ButtonComponent from '../ButtonComponent';

export default function Home() {
    const Email = useSelector((state) => state.recruiter_email);
    console.log("Email Logged In", Email)

    const dispatch = useDispatch();

    const Logout = () => {
        dispatch(setRecruiterEmail(""));
        window.location = '/';
    };

    const [activePoint, setActivePoint] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setActivePoint((prev) => (prev < 3 ? prev + 1 : 1));
        }, 2000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div className='text-white font-poppins overflow-hidden'>
            <div className='bg-[#1A1919] pb-10'>
                <div>
                    <LoginHeader />
                    <div className='sec-1 text-lg 2xl:text-xl min-h-screen md:min-h-[75vh] flex items-center justify-center text-center relative' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                        <div className='z-10'>
                            <div className='flex justify-center'>
                                <h1 className=' text-3xl 2xl:text-4xl font-bold text-transparent bg-gradient-to-r from-[#31989B] to-[#4A5CA4] bg-clip-text '
                                >Skill Realm</h1>
                            </div>
                            <p className='italic mt-10'>"Unlock Talent, Streamline Hiring: Your Gateway to Unbiased Recruitment"</p>
                            <p className='mt-10 w-[90%] md:w-[80%] mx-auto'>Enter a realm where recruitment transcends reality. With the mystical D5art Passport and D5art ID, your talents shine while your personal details remain protected. Here, hiring is unbiased, and your future is crafted in the magic of fairness and innovation.</p>
                            <div className='flex flex-col md:flex-row justify-center gap-5 md:gap-7 mt-10 md:mt-[4rem]'>
                             <a   className='mx-auto md:mx-0 '><ButtonComponent label="Unbiased CV" /></a> 
                              <a  href='/RMS/Recruiter/SkillArenalist'> <button className='mx-auto md:mx-0  font-play bg-[#d9d9d9]/35 backdrop-blur-md py-2 px-5 rounded-full'>
                                    Explore Marketplace
                                </button>
                                </a>
                            </div>
                        </div>
                        <div className='absolute flex justify-around z-0'>
                            <div className='bg-[#00FFC2]/5 blur-3xl w-[20rem] h-[20rem] rounded-full translate-y-32'></div>
                            <div className='bg-[#7B00FF]/10 blur-3xl w-[20rem] h-[20rem] rounded-full'></div>
                            <div className='bg-[#4B53FC]/15 blur-3xl w-[20rem] h-[20rem] rounded-full translate-y-32'></div>
                        </div>
                        <div className=''>
                            <img src='/assets/C1.png' className='absolute top-[15%] left-[20%] w-12 h-12 fa-bounce'></img>
                            <img src='/assets/C2.png' className='absolute top-[35%] right-[10%] w-12 h-12 fa-bounce'></img>
                        </div>
                    </div>
                </div>
               <div className='sec-2 flex items-center min-h-screen' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                    <div className='flex flex-col md:flex-row gap-5 md:gap-0 w-[90%] md:w-[80%] mx-auto '>
                        <div className='md:w-[50%] relative flex items-center justify-center'>
                            <div className='z-10'>
                                <img src='/assets/Seekeravatar.png' className='w-[90%] mx-auto'></img>
                                <div className='flex justify-center'>
                                    <a href='/SeekerRegistration'>
                                        <ButtonComponent label="Join Now" />
                                    </a>
                                </div>
                            </div>
                            <div className='absolute bg-[#4B53FC]/15 blur-3xl w-[25rem] h-[25rem] rounded-full z-0'></div>
                            <div>
                                <img src='/assets/C3.png' className='absolute top-[15%] left-0 w-12 h-12 fa-bounce'></img>
                                <img src='/assets/C2.png' className='absolute bottom-[10%] right-[5%] w-12 h-12 fa-bounce'></img>
                            </div>
                        </div>
                        <div className='md:w-[50%] grid content-center'>
                            <div className='flex justify-center lg:justify-start'>
                                <h1 className='font-museo text-3xl font-bold text-transparent bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00] bg-clip-text'>
                                    Job seeker / learner
                                </h1>
                            </div>

                            <div className="flex flex-col p-6 text-white">
                                <div className="flex items-start space-x-4">
                                    <div className="flex flex-col items-center pt-2">
                                        <div className={`w-4 h-4 bg-gradient-to-r z-10 from-[#0073FF] to-[#1CD9FF] rounded-full flex items-center
                                                ${activePoint === 1 ? 'glow' : ''}`}>
                                            <div className='bg-[#1A1919] z-10 h-2 w-2 m-auto rounded-full'></div>
                                        </div>
                                        <div className="h-[136px] lg:h-28 border-l-2 border-dotted border-[#00A2FF]"></div>
                                    </div>

                                    <div>
                                        <h2 className="text-[#FAE544] lg:text-start font-bold text-lg">Register</h2>
                                        <p>
                                            Step into the realm of possibilities—job seekers, claim your D5art Passport and
                                            embark on your journey to limitless opportunities.
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-start space-x-4">
                                    <div className="flex flex-col items-center">
                                        <div
                                            className={`w-4 h-4 bg-gradient-to-r z-0 from-[#0073FF] to-[#1CD9FF] rounded-full flex items-center 
                                                ${activePoint === 2 ? 'glow' : ''}`}
                                        >
                                            <div className='bg-[#1A1919] z-10 h-2 w-2 m-auto rounded-full'></div>
                                        </div>
                                        <div className=" h-28 border-l-2 border-dotted border-[#00A2FF]"></div>
                                    </div>
                                    <div className='-translate-y-2'>
                                        <h2 className="text-[#FAE544] font-bold text-lg">Upskill</h2>
                                        <p>
                                            "Unlock hidden powers within—upskill with D5art and transform your abilities into legendary talents!"
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-start space-x-4">
                                    <div className="flex flex-col items-center">
                                        <div
                                            className={`w-4 h-4 bg-gradient-to-r from-[#0073FF] to-[#1CD9FF] rounded-full flex items-center 
                                                ${activePoint === 3 ? 'glow' : ''}`}
                                        >
                                            <div className='bg-[#1A1919] h-2 w-2 m-auto rounded-full'></div>
                                        </div>
                                    </div>
                                    <div className='-translate-y-2'>
                                        <h2 className="text-[#FAE544] font-bold text-lg">Get Hire</h2>
                                        <p>
                                            "Summon your destiny—get hired with D5art and let your talents shine in the enchanted realm of opportunities!"
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sec-4 flex items-center min-h-screen' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                    <div className='flex flex-col md:flex-row-reverse gap-5 md:gap-0 w-[90%] md:w-[80%] mx-auto'>
                        <div className='md:w-[50%] relative flex items-center justify-center'>
                            <div className='z-10'>
                                <img src='/assets/Validateavatar.png'></img>
                                <div className='flex justify-center'>
                                    <a href='/ValidatorRegistration'>
                                        <ButtonComponent label="Join Now" />
                                    </a>
                                </div>
                            </div>
                            <div className='absolute bg-[#00FFC2]/10 blur-3xl w-[25rem] h-[25rem] rounded-full z-0'></div>
                            <div>
                                <img src='/assets/C1.png' className='absolute  bottom-[10%] left-0 w-12 h-12 fa-bounce'></img>
                                <img src='/assets/C2.png' className='absolute top-[15%] right-[15%] w-12 h-12 fa-bounce'></img>
                            </div>
                        </div>
                        <div className='md:w-[50%] grid content-center'>
                            <div className='flex justify-center lg:justify-start'>
                                <h1 className='font-museo text-3xl font-bold text-transparent bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00] bg-clip-text'>
                                    Validator
                                </h1>
                            </div>
                            <div className="flex flex-col p-6 text-white">
                                <div className="flex items-start space-x-4">
                                    <div className="flex flex-col items-center pt-2">
                                        <div className={`w-4 h-4 bg-gradient-to-r z-10 from-[#0073FF] to-[#1CD9FF] rounded-full flex items-center
                                                ${activePoint === 1 ? 'glow' : ''}`}>
                                            <div className='bg-[#1A1919] z-10 h-2 w-2 m-auto rounded-full'></div>
                                        </div>
                                        <div className=" h-28 border-l-2 border-dotted border-[#00A2FF]"></div>
                                    </div>

                                    <div>
                                        <h2 className="text-[#FAE544] font-bold text-lg">Register</h2>
                                        <p>
                                            "Answer the call of wisdom—become a D5art Validator and guide seekers on their path to mastery!"
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-start space-x-4">
                                    <div className="flex flex-col items-center">
                                        <div
                                            className={`w-4 h-4 bg-gradient-to-r z-0 from-[#0073FF] to-[#1CD9FF] rounded-full flex items-center 
                                                ${activePoint === 2 ? 'glow' : ''}`}
                                        >
                                            <div className='bg-[#1A1919] z-10 h-2 w-2 m-auto rounded-full'></div>
                                        </div>
                                        <div className=" h-28 border-l-2 border-dotted border-[#00A2FF]"></div>
                                    </div>
                                    <div className='-translate-y-2'>
                                        <h2 className="text-[#FAE544] font-bold text-lg">Create Question set</h2>
                                        <p>
                                            "Forge the challenge—craft your question set as a D5art Validator and shape the journey of aspiring talents!"                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-start space-x-4">
                                    <div className="flex flex-col items-center">
                                        <div
                                            className={`w-4 h-4 bg-gradient-to-r from-[#0073FF] to-[#1CD9FF] rounded-full flex items-center 
                                                ${activePoint === 3 ? 'glow' : ''}`}
                                        >
                                            <div className='bg-[#1A1919] h-2 w-2 m-auto rounded-full'></div>
                                        </div>
                                    </div>
                                    <div className='-translate-y-2'>
                                        <h2 className="text-[#FAE544] font-bold text-lg">Validate</h2>
                                        <p>
                                            "Cast your verdict—validate skills with D5art and empower talents to rise to their full potential!"
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sec-5 flex items-center min-h-screen' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                    <div className='flex flex-col md:flex-row gap-5 md:gap-0 w-[90%] md:w-[80%] mx-auto'>
                        <div className='md:w-[50%] relative flex items-center justify-center'>
                            <div className='z-10'>
                                <img src='/assets/Recruiteravatar.png' className='mx-auto w-[75%] 2xl:w-[80%]'></img>
                                {/* <div className='flex justify-center mt-10'>
                                    <a href='/Register'>
                                        <ButtonComponent label="Join Now" />
                                    </a>
                                </div> */}
                            </div>
                            <div className='absolute bg-[#7B00FF]/10 blur-3xl w-[25rem] h-[25rem] rounded-full z-0'></div>
                            <div className='Bouncing Sphere'>
                                <img src='/assets/C1.png' className='absolute bottom-[10%] left-0 w-12 h-12 fa-bounce'></img>
                                <img src='/assets/C2.png' className='absolute top-[5%] right-[15%] w-12 h-12 fa-bounce'></img>
                            </div>
                        </div>
                        <div className='md:w-[50%] grid content-center'>
                            <div className='flex justify-center lg:justify-start'>
                                <h1 className='font-museo text-3xl font-bold text-transparent bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00] bg-clip-text'>
                                    Recruiter
                                </h1>
                            </div>

                            <div className="flex flex-col p-6 text-white">
                                <div className="flex items-start space-x-4">
                                    <div className="flex flex-col items-center pt-2">
                                        <div className={`w-4 h-4 bg-gradient-to-r z-10 from-[#0073FF] to-[#1CD9FF] rounded-full flex items-center
                                            ${activePoint === 1 ? 'glow' : ''}`}>
                                            <div className='bg-[#1A1919] z-10 h-2 w-2 m-auto rounded-full'></div>
                                        </div>
                                        <div className=" h-28 border-l-2 border-dotted border-[#00A2FF]"></div>
                                    </div>

                                    <div>
                                        <h2 className="text-[#FAE544] font-bold text-lg">Registration</h2>
                                        <p>
                                            "Enter the citadel of talent—register as a D5art recruiter and discover the hidden gems of tomorrow!"
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-start space-x-4">
                                    <div className="flex flex-col items-center">
                                        <div
                                            className={`w-4 h-4 bg-gradient-to-r z-0 from-[#0073FF] to-[#1CD9FF] rounded-full flex items-center 
                                            ${activePoint === 2 ? 'glow' : ''}`}
                                        >
                                            <div className='bg-[#1A1919] z-10 h-2 w-2 m-auto rounded-full'></div>
                                        </div>
                                        <div className=" h-28 border-l-2 border-dotted border-[#00A2FF]"></div>
                                    </div>
                                    <div className='-translate-y-2'>
                                        <h2 className="text-[#FAE544] font-bold text-lg">Seek for passport</h2>
                                        <p>
                                            "Embark on your quest—seek your D5art Passport and unlock the gateway to boundless opportunities!"
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-start space-x-4">
                                    <div className="flex flex-col items-center">
                                        <div
                                            className={`w-4 h-4 bg-gradient-to-r from-[#0073FF] to-[#1CD9FF] rounded-full flex items-center 
                                            ${activePoint === 3 ? 'glow' : ''}`}
                                        >
                                            <div className='bg-[#1A1919] h-2 w-2 m-auto rounded-full'></div>
                                        </div>
                                    </div>
                                    <div className='-translate-y-2'>
                                        <h2 className="text-[#FAE544] font-bold text-lg">Purchase the passport</h2>
                                        <p>
                                            "Claim your destiny—purchase your D5art Passport and step into a world where talent meets opportunity!"
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <style>
                {`
                .glow {
                    box-shadow: 0 0 15px 5px #1CD9FF;
                    transition: box-shadow 0.5s ease;
                }
                `}
            </style>
        </div>
    )
}