import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setValidatorEmail } from "../store";
import { Link, useNavigate } from 'react-router-dom';


export default function ValidatorHeader() {
  const [isDropdown, setDropdown] = useState(false);



  const email = useSelector((state) => state.validator_email);
  console.log("Email Logged In2222222", email)

  const dispatch = useDispatch();


  const Logout = () => {


    dispatch(setValidatorEmail(""));
    window.location = '/';
  };

  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };


  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdown]);


  return (
    <div>
      <nav className='flex p-7  lg:px-10  font-poppins '>
        <div className='w-[85%] lg:w-[15%] 2xl:w-[10%]'>
          <div className='w-[80%]'>
            <a href='/RMS/Validator'><img src='/assets/d5art_white.png' className='h-10'></img></a>
          </div>
        </div>
        <div className='w-[15%] lg:w-[85%] 2xl:w-[90%] hidden md:table'>
          <div className=' flex '>
            <div className='w-[80%] text-gray-300 font-bold flex justify-center gap-14 mt-3 '>
              <a href='/RMS/Validator/MangeQA'>Manage Q/A</a>
              <a href='/RMS/Validator/Create'>Create Q/A</a>


              <a href='/RMS/Validator/Wallet'>Wallet</a>
              <a href='/ex/validator/faq'>FAQ</a>

            </div>
            <div className='w-[20%] '>
              <div className="relative">
                <button className="flex px-5 py-2  text-white" onClick={handleDropdownToggle}>
                  <button className="p-3 rounded-full border border-sky-500 mr-3"></button>
                  <div className='absolute -translate-x-2 -translate-y-2'><img className='h-[3rem]' src='/assets/image 90.png'></img></div> {email.substr(0, 10) + "...."}

                  <i class="fa-solid fa-chevron-down pl-3 mt-1"></i>
                </button>

                {dropdownVisible && (
                  <div className="absolute top-full z-10 left-0 mt-2 bg-white border border-gray-300 rounded-md shadow-md animate__animated animate__fadeInUp ">
                    <div className='py-2 px-9 rounded-md bg-black text-white'>
                      <a href='/RMS/Validator/ValidatorDashboard' className='text-center ' >
                        <i className="fa-solid fa-user pr-3"></i> Dashboard</a>
                      <button onClick={Logout} className='text-center'>
                        <i className='fa-solid fa-door-open pr-3'></i>Log out
                      </button>
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>

        <div className='w-[15%] table lg:hidden grid content-center'>
          <div className='flex justify-center '>
            <button onClick={handleDropdown} ref={buttonRef}>
              <button className="p-3 rounded-full border border-sky-500 mr-3"></button>
              <div className='absolute -translate-y-10 -translate-x-2'><img className='h-[3rem]' src='/assets/image 90.png'></img></div>        </button>
            {isDropdown && (
              <div className='dropdown-content text-black z-50 w-[10rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp' ref={dropdownRef}>
                <div className='flex flex-col gap-3 bg-black text-white rounded-lg p-5'>
                  <a href='/RMS/Validator/MangeQA' className='text-center'>Manage Q/A</a>
                  <a href='/RMS/Validator/Create' className='text-center'>Create Q/A</a>

                  <a href='/RMS/Validator/Wallet' className='text-center'>Wallet</a>
                  <a href='/ex/validator/faq' className='text-center'>FAQ</a>

                  <a href='/RMS/Validator/ValidatorDashboard' className='text-center'> <i className="fa-solid fa-user pr-3"></i> Dashboard</a>
                  <button onClick={Logout} className='text-center'>
                    <i className='fa-solid fa-door-open pr-3'></i>Log out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  )
}
