import React, { useState } from 'react'
import LoginHeader from '../Seeker/LoginHeader'
import Footer from '../Footer'
import Select from 'react-select';
import './DNA.css'
import Aos from 'aos';
import 'aos/dist/aos.css';

Aos.init();

export default function DnaHome() {
    const [isactive, setactive] = useState('wealth');
    const handleActivetab = (tab) => {
        setactive(tab)
    }

    const [Purpose, setPurpose] = useState(null); 

    const Selectpurpose = [
        { value: 'CXO', label: 'CXO' },
        { value: 'Student', label: 'Student' },
        { value: 'Graduate', label: 'Graduate' },
        { value: 'Working professional', label: 'Working professional' },
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '0px',
            borderBottom: '1px dashed',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
            outline: 'none !important',
            ring: 'none',
            borderRadius: '0px',
            '&:hover': {                      
                borderBottom: '1px dashed white',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'lightgray',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            backgroundColor: state.isSelected ? '#08f' : '#000',
            color: '#fff',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        input: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#000',
        }),
    };

    const handlePurpose = (selectedOption) => {
        setPurpose(selectedOption);
    };


    return (
        <div className='text-white font-poppins overflow-hidden'>
            <div className='bg-[#1A1919]'>
                <LoginHeader />
                <div className='sec-1 text-lg 2xl:text-xl flex items-center justify-center text-center relative' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                    <div className='z-20'>
                        <div className='flex justify-center'>
                            <h1 className='text-2xl md:text-3xl 2xl:text-4xl font-bold text-transparent bg-gradient-to-r from-[#31989B] to-[#4A5CA4] bg-clip-text '
                            >Let’s Nurture your Nature</h1>
                        </div>
                        <p className='italic mt-7 w-[90%] md:w-[60%] mx-auto'>“DNA is the script of life, and with each enhancement, we evolve the story—turning potential into power, and existence into evolution”</p>
                        <img src='/assets/DNAhero.png' className='w-[90%] md:w-[25%] 2xl:w-[35%] mt-5 mx-auto'></img>
                        <div className='flex flex-col md:flex-row justify-center gap-5 md:gap-7 mt-10'>
                            <a href='#'> <button className='mx-auto md:mx-0 font-play bg-[#d9d9d9]/35 backdrop-blur-md py-2 px-7 rounded-full'>
                                Book my kit
                            </button></a>
                        </div>
                    </div>
                    <div className='absolute flex justify-between z-10 w-[100%]'>
                        <img src='/assets/Heroleft.png' className='w-[20%]'></img>
                        <img src='/assets/Heroright.png' className='w-[20%]'></img>
                    </div>
                    <div className='absolute flex justify-around z-0'>
                        <div className='bg-[#00FFC2]/5 blur-3xl w-[20rem] h-[20rem] rounded-full translate-y-32'></div>
                        <div className='bg-[#7B00FF]/10 blur-3xl w-[20rem] h-[20rem] rounded-full'></div>
                        <div className='bg-[#4B53FC]/15 blur-3xl w-[20rem] h-[20rem] rounded-full translate-y-32'></div>
                    </div>
                    <div>
                        <img src='/assets/C1.png' className='absolute top-10 md:top-0 left-[20%] w-5 md:w-12 h-5 md:h-12 fa-bounce'></img>
                    </div>
                </div>
                <div className='sec-2 py-10' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                    <div className='w-[90%] mx-auto'>
                        <div className='flex justify-start'>
                            <h1 className='bg-gradient-to-r from-[#31989B] to-[#F29D38] bg-clip-text text-transparent text-xl md:text-3xl font-bold'>What awaits you</h1>
                        </div>
                        <div className='flex justify-center my-7'>
                            <div className='border border-[#0E2DA7] rounded-full'>
                                <button className={`border-2  font-bold px-5 py-1 rounded-full duration-500 ${isactive === 'wealth' ? 'border-[#F29D38]' : 'border-transparent'}`} onClick={() => { handleActivetab('wealth') }}><p className={` bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] bg-clip-text duration-500 ${isactive === 'wealth' ? 'text-transparent' : ''}`} >Wealth</p></button>
                                <button className={`border-2  font-bold px-5 py-1 rounded-full duration-500 ${isactive === 'health' ? 'border-[#F29D38]' : 'border-transparent'}`} onClick={() => { handleActivetab('health') }}><p className={` bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] bg-clip-text duration-500 ${isactive === 'health' ? 'text-transparent' : ''}`} >Health</p></button>
                            </div>
                        </div>
                        {isactive === 'wealth' && (
                            <div data-aos='fade-in' data-aos-duration='1000'>
                                <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-10 pb-10'>
                                    <div className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Personalized Skill Development</h1>
                                            <img src='/assets/W1.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Suggests optimal learning methods based on cognitive and behavioral genetic traits, enhancing upskilling efforts.</p>
                                        </div>
                                    </div>
                                    <div className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Tailored Learning</h1>
                                            <img src='/assets/W2.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Aligns genetic predispositions with suitable professions or industries for greater job satisfaction and success.</p>
                                        </div>
                                    </div>
                                    <div className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Resilience and adaptability</h1>
                                            <img src='/assets/W3.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Assesses traits linked to adaptability and stress management, crucial for thriving in dynamic work environments.</p>
                                        </div>
                                    </div>
                                    <div className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Entrepreneurial Potential</h1>
                                            <img src='/assets/W4.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Helps individuals understand work styles or focus patterns to boost efficiency and performance.</p>
                                        </div>
                                    </div>
                                    <div className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Leadership Potential</h1>
                                            <img src='/assets/W5.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'> Identifies traits such as decision-making, emotional intelligence, and risk management, helping individuals pursue leadership roles.</p>
                                        </div>
                                    </div>
                                    <div className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Personalized Skill Development</h1>
                                            <img src='/assets/W6.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Identifies natural aptitudes and talents, allowing individuals to focus on strengths for career growth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isactive === 'health' && (
                            <div data-aos='fade-in' data-aos-duration='1000'>
                                <div className='flex flex-wrap justify-center gap-5 lg:gap-10 pb-10'>
                                    <div className='w-[100%] md:w-[46%] lg:w-[30%] bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Personalized Medicine</h1>
                                            <img src='/assets/H1.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Tailors treatments and medications to individual genetic profiles for more effective outcomes.</p>
                                        </div>
                                    </div>
                                    <div className='w-[100%] md:w-[46%] lg:w-[30%] bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Diet and Nutrition Optimization</h1>
                                            <img src='/assets/H2.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Guides personalized dietary plans based on genetic responses to nutrients.</p>
                                        </div>
                                    </div>
                                    <div className='w-[100%] md:w-[46%] lg:w-[30%] bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Drug Response</h1>
                                            <img src='/assets/H3.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'> Helps determine how individuals metabolize certain medications, reducing adverse effects.</p>
                                        </div>
                                    </div>
                                    <div className='w-[100%] md:w-[46%] lg:w-[30%] bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Preventive Care</h1>
                                            <img src='/assets/H4.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Enables early interventions and lifestyle adjustments to prevent potential health issues.</p>
                                        </div>
                                    </div>
                                    <div className='w-[100%] md:w-[46%] lg:w-[30%] bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Disease Risk Prediction</h1>
                                            <img src='/assets/H5.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Identifies genetic predispositions to conditions like cancer, heart disease, or diabetes.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='sec-3 pb-10 flex items-center' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                    <div className='w-[90%] mx-auto'>
                        <div className='flex justify-start'>
                            <h1 className='bg-gradient-to-r from-[#31989B] to-[#F29D38] bg-clip-text text-transparent text-xl md:text-3xl font-bold'>Be an Early Adapter</h1>
                        </div>
                        <div className='flex justify-center items-center my-7 font-semibold text-center md:text-start'>
                            <p className=' bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] text-transparent bg-clip-text duration-500'>Book the kit</p>
                            <hr className='w-20 h-[1px] border-dashed mx-1'></hr>
                            <p>Generate Report</p>
                            <hr className='w-20 h-[1px] border-dashed mx-1'></hr>
                            <p>Assess & Enhance</p>
                        </div>
                        <div className='flex flex-col lg:flex-row items-center gap-5 lg:gap-0'>
                            <div className='w-[100%] lg:w-[50%] text-center'>
                                <img src='/assets/DNAform.png' className='w-[80%] 2xl:w-[60%] mx-auto'></img>
                                <p className='text-lg italic'>“Your Data Your Control”</p>
                                <p className='text-sm 2xl:w-[60%] mx-auto'>The Data test report is stored on Blockchain where the user only have the access to their own data</p>
                            </div>
                            <div className='w-[100%] lg:w-[50%]'>
                                <div class="w-[100%] md:w-[90%] 2xl:w-[80%] bg-[#232323] mx-auto relative p-7 md:p-10 rounded-2xl shadow-md">
                                    <h1 className='text-center text-[#F29D38] text-lg font-semibold'>Book your DNA test kit</h1>
                                    <div className='grid gap-5'>
                                        <div>
                                            <label className='text-sm'>Full Name :</label>
                                            <input className='border-b border-dashed outline-none w-[100%] bg-[#232323]'></input>
                                        </div>
                                        <div className='flex flex-col md:flex-row gap-5'>
                                            <div>
                                                <label className='text-sm'>Mail ID :</label>
                                                <input type='email' className='border-b border-dashed outline-none w-[100%] bg-[#232323]'></input>
                                            </div>
                                            <div>
                                                <label className='text-sm'>Mobile :</label>
                                                <input className='border-b border-dashed outline-none w-[100%] bg-[#232323]'></input>
                                            </div>
                                        </div>
                                        <div>
                                            <label className='text-sm'>Address :</label>
                                            <input className='border-b border-dashed outline-none w-[100%] bg-[#232323]'></input>
                                        </div>
                                        <div>
                                            <label className='text-sm'>Country :</label>
                                            <input className='border-b border-dashed outline-none w-[100%] bg-[#232323]'></input>
                                        </div>
                                        <div>
                                            <label className='text-sm'>Pin code / Zip code :</label>
                                            <input className='border-b border-dashed outline-none w-[100%] bg-[#232323]'></input>
                                        </div>
                                        <div className='flex flex-col md:flex-row gap-5'>
                                            <div className='w-[100%] md:w-[50%]'>
                                                <label className='text-sm'>Are you here as :</label>
                                                <Select
                                                    className="w-[100%]"
                                                    value={Purpose}
                                                    onChange={handlePurpose}
                                                    options={Selectpurpose}
                                                    styles={customStyles}
                                                    placeholder="Prefer one"
                                                />
                                            </div>
                                            <div className='w-[100%] md:w-[50%]'>
                                                <label className='text-sm'>Referral Code (optional)</label>
                                                <input className='border-b border-dashed outline-none w-[100%] bg-[#232323] h-[38px]'></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-between mt-5 font-semibold'>
                                        <h1 className='text-lg '>Price: <span className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] bg-clip-text text-transparent'>$210</span></h1>
                                        <button className=' font-play bg-[#d9d9d9]/35 backdrop-blur-md py-2 px-5 rounded-full'>
                                            Book my kit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
